import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PrepPos from "../../../TestData/prep_pos";
import Plates from "../../../TestData/plates";
import axiosInstance from "../../../../axios";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:before": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:after": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "& .MuiSelect-select": {
      fontSize: "1.7rem",
      color: "#FEFFFF",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#17252A",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#17252A",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInputBase-input": {
      color: "#17252A",
    },
  },
  icon: {
    fill: "#FEFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  robotTray: {
    // height: "125px",
    padding: "10%",
    // width: "190px",
    marginBottom: "10px",
  },
  blueRobotTray: {
    backgroundColor: "blue",
  },
  plate: {
    padding: "5%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Preparation() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  const [barcode, setBarcode] = useState("");
  const [positions, setPositions] = useState([]);
  const [loadedPrescription, setLoadedPrescription] = useState("");
  const [confirmAssayOpen, setConfirmAssayOpen] = useState(true);
  const [loadPrescriptionOpen, setLoadPrescriptionOpen] = useState(false);
  const [verifyPlateConfirm, setVerifyPlateConfirm] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [scannedBarcodes, setScannedBarcode] = useState([]);
  const [expectedPlates, setExpectedPlates] = useState(positions.length);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [scannedPlateCount, setScannedPlateCount] = useState(
    scannedBarcodes.length
  );
  const [errorMessage, setErrorMessage] = useState([""]);
  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };
  const plateInfo = {
    // tray_count: data.data.trays_set.filter((tray) => tray.tray_status === "Order Received")
    plate_count: data.data.plates.filter(
      (plate) => plate.plate_status === "In Consolidation"
    ).length,
    plates_to_process: data.data.plates.filter(
      (plate) => plate.plate_status === "In Consolidation"
    ),
  };
  const initialData = {
    // loadedPrescription: "",
    pos2: "",
    pos3: "",
    pos4: "",
    pos5: "",
    pos6: "",
    pos7: "",
    pos8: "",
    pos9: "",
    pos10: "",
    pos11: "",
    pos12: "",
    pos2Color: "grey",
    pos3Color: "grey",
    pos4Color: "grey",
    pos5Color: "grey",
    pos6Color: "grey",
    pos7Color: "grey",
    pos8Color: "grey",
    pos9Color: "grey",
    pos10Color: "grey",
    pos11Color: "grey",
    pos12Color: "grey",
    platesExpected: positions.length,
    platesScanned: 0,
  };
  const [formData, setFormData] = useState(initialData);

  const initialFieldState = {
    pos2: true,
    pos3: true,
    pos4: true,
    pos5: true,
    pos6: true,
    pos7: true,
    pos8: true,
    pos9: true,
    pos10: true,
    pos11: true,
    pos12: true,
  };
  const [enabledOptions, setEnabledOptions] = useState(initialFieldState);

  const initialPositionLabels = {
    pos2: "Position 2",
    pos3: "Position 3",
    pos4: "Position 4",
    pos5: "Position 5",
    pos6: "Position 6",
    pos7: "Position 7",
    pos8: "Position 8",
    pos9: "Position 9",
    pos10: "Position 10",
    pos11: "Position 11",
    pos12: "Position 12",
  };
  const [positionLabels, setPositionLabels] = useState(initialPositionLabels);

  const prep_blocks = [
    {
      id: 1,
      label: "Assay Tray - position 1",
      bgColor: "grey",
    },
    {
      id: "pos4",
      label: positionLabels.pos4,
      disabled: enabledOptions.pos4,
      value: formData.pos4,
      bgColor: formData.pos4Color,
    },
    {
      id: "pos7",
      label: positionLabels.pos7,
      disabled: enabledOptions.pos7,
      value: formData.pos7,
      bgColor: formData.pos7Color,
    },
    {
      id: "pos10",
      label: positionLabels.pos10,
      disabled: enabledOptions.pos10,
      value: formData.pos10,
      bgColor: formData.pos10Color,
    },
    {
      id: "pos2",
      label: positionLabels.pos2,
      disabled: enabledOptions.pos2,
      value: formData.pos2,
      bgColor: formData.pos2Color,
    },
    {
      id: "pos5",
      label: positionLabels.pos5,
      disabled: enabledOptions.pos5,
      value: formData.pos5,
      bgColor: formData.pos5Color,
    },
    {
      id: "pos8",
      label: positionLabels.pos8,
      disabled: enabledOptions.pos8,
      value: formData.pos8,
      bgColor: formData.pos8Color,
    },
    {
      id: "pos11",
      label: positionLabels.pos11,
      disabled: enabledOptions.pos11,
      value: formData.pos11,
      bgColor: formData.pos11Color,
    },
    {
      id: "pos3",
      label: positionLabels.pos3,
      disabled: enabledOptions.pos3,
      value: formData.pos3,
      bgColor: formData.pos3Color,
    },
    {
      id: "pos6",
      label: positionLabels.pos6,
      disabled: enabledOptions.pos6,
      value: formData.pos6,
      bgColor: formData.pos6Color,
    },
    {
      id: "pos9",
      label: positionLabels.pos9,
      disabled: enabledOptions.pos9,
      value: formData.pos9,
      bgColor: formData.pos9Color,
    },
    {
      id: "pos12",
      label: positionLabels.pos12,
      disabled: enabledOptions.pos12,
      value: formData.pos12,
      bgColor: formData.pos12Color,
    },
  ];

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {
    if (positions.length != 0) {
      let plateMarker = positions[0].marker
      let assayPosition = "pos" + positions[0].position
      setFormData({
        ...formData,
        [plateMarker.toLowerCase()]: barcode,
        // [positions[0].marker.toLowerCase()]: barcode,
        platesScanned: formData.platesScanned + 1,
      });
      setExpectedPlates(positions.length);
      verifyPlateData(assayPosition, plateMarker.toLowerCase());
      // setPositionLabels({
      //   ...positionLabels,
      //   [assayPosition]: plateMarker
      // })
      console.log(positionLabels)
      setEnabledOptions({
        ...enabledOptions,
        [assayPosition]: true,
        // [positions[0].marker.toLowerCase()]: true,
      });
    }
  }, [loadedPrescription, positions]);
  useEffect(() => {}, [formData]);
  useEffect(() => {}, [loadedPrescription]);
  useEffect(() => {
    setScannedPlateCount(scannedBarcodes.length);
  }, [scannedBarcodes]);

  const handleSubmit = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .post("prepared/", scannedBarcodes)
        .then((res) => {
          setAreYouSure(false);
          setSubmitted(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyPlateData = (id, marker) => {
    let error = false;
    let foundPlate = false;
    let plate_to_verify = null;
    let prescription_to_verify = null;
    let token = localStorage.getItem("access_token"); // get the access token from login
    let user = jwt_decode(token); // decode token to get info

    if (barcode != "") {
    }

    // plateInfo.plates_to_process.map((plate) => {
    //   if (plate.plate_barcode == barcode) {
    //     foundPlate = true;
    //     plate_to_verify = plate;
    //     prescription_to_verify = tray.prescription.prescription;
    //   }
    // });
    console.log("Hellooooooooooo");
    data.data.trays_set.map((tray) => {
      if (tray.plates.length > 0) {
        tray.plates.map((plate) => {
          
          if (plate.plate_barcode == barcode && plate.plate_status == "In Consolidation") {
            foundPlate = true;
            plate_to_verify = plate;
            prescription_to_verify = tray.prescription.prescription;
          }
        });
      }
    });

    // if (!foundPlate) {
    //   error = true;
    //   setErrorMessage(["plate scanned was not found"]);
    // }
    if (!foundPlate) {
      error = true;
      setErrorMessage(["plate scanned was not found"]);
    } else {
      if (prescription_to_verify != loadedPrescription) {
        error = true;
        setErrorMessage(["plate scanned does not match prescription"]);
      } else {
        scannedBarcodes.map((existingBarcode) => {
          if (existingBarcode.plate_barcode == barcode) {
            error = true;
            setErrorMessage(["barcode was already scanned"]);
          }
        });
        if (!error) {
          console.log("HIHIHIHIHI");
          console.log(loadedPrescription)
          console.log(prescription_to_verify)
          setErrorMessage([""]);
          setScannedBarcode([
            ...scannedBarcodes,
            {
              plate_barcode: Number(barcode),
              order: clientInfo.client_order,
              marker: marker.toLowerCase(), // id.toUpperCase(),
              prescription: loadedPrescription,
              user:user.user_id
            },
          ]);

          setEnabledOptions({
            ...enabledOptions,
            [id]: true,
          });
          setFormData({
            ...formData,
            [id]: barcode,
            platesScanned: formData.platesScanned + 1,
          });
          setAreYouSure(true);
          setVerifyPlateConfirm(true);
        }
      }
    }
  };

  const handleChange = (e) => {
    setBarcode(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleLoadPrescriptionOpen = () => {
    setLoadPrescriptionOpen(true);
  };

  const handleCloseConfirmPlate = () => {
    setVerifyPlateConfirm(false);
    console.log(scannedBarcodes);
    if (scannedBarcodes.length === expectedPlates) {
      // enable submit button
      setEnableSubmit(true);
    }
  };

  const handleLoadPrescriptionClose = (prescrip, pos) => {
    setLoadPrescriptionOpen(false);
    setLoadedPrescription(prescrip);
    setPositions(pos);
    let pos2 = true;
    let pos3 = true;
    let pos4 = true;
    let pos5 = true;
    let pos6 = true;
    let pos7 = true;
    let pos8 = true;
    let pos9 = true;
    let pos10 = true;
    let pos11 = true;
    let pos12 = true;
    let pos2Color = "grey";
    let pos3Color = "grey";
    let pos4Color = "grey";
    let pos5Color = "grey";
    let pos6Color = "grey";
    let pos7Color = "grey";
    let pos8Color = "grey";
    let pos9Color = "grey";
    let pos10Color = "grey";
    let pos11Color = "grey";
    let pos12Color = "grey";
    let pos2Label = "Position 2";
    let pos3Label = "Position 3";
    let pos4Label = "Position 4";
    let pos5Label = "Position 5";
    let pos6Label = "Position 6";
    let pos7Label = "Position 7";
    let pos8Label = "Position 8";
    let pos9Label = "Position 9";
    let pos10Label = "Position 10";
    let pos11Label = "Position 11";
    let pos12Label = "Position 12";
    pos.map((pos) => {
      let enablePos = prep_blocks.filter((e) => e.id == "pos" + pos.position);
      console.log(pos.marker);
      if (enablePos[0].id.toLowerCase() === "pos2") {
        pos2 = false;
        pos2Color = "#00a152";
        pos2Label = pos.marker;
        // pos2Label = pos.marker.toUpperCase()
      } else if (enablePos[0].id.toLowerCase() === "pos3") {
        pos3 = false;
        pos3Color = "#00a152";
        pos3Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos4") {
        pos4 = false;
        pos4Color = "#00a152";
        pos4Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos5") {
        pos5 = false;
        pos5Color = "#00a152";
        pos5Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos6") {
        pos6 = false;
        pos6Color = "#00a152";
        pos6Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos7") {
        pos7 = false;
        pos7Color = "#00a152";
        pos7Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos8") {
        pos8 = false;
        pos8Color = "#00a152";
        pos8Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos9") {
        pos9 = false;
        pos9Color = "#00a152";
        pos9Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos10") {
        pos10 = false;
        pos10Color = "#00a152";
        pos10Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos11") {
        pos11 = false;
        pos11Color = "#00a152";
        pos11Label = pos.marker;
      } else if (enablePos[0].id.toLowerCase() === "pos12") {
        pos12 = false;
        pos12Color = "#00a152";
        pos12Label = pos.marker;
      }
      // enablePos[0].label.toLowerCase() = false;
    });
    setEnabledOptions({
      pos2: pos2,
      pos3: pos3,
      pos4: pos4,
      pos5: pos5,
      pos6: pos6,
      pos7: pos7,
      pos8: pos8,
      pos9: pos9,
      pos10: pos10,
      pos11: pos11,
      pos12: pos12,
    });
    setFormData({
      ...formData,
      pos2Color: pos2Color,
      pos3Color: pos3Color,
      pos4Color: pos4Color,
      pos5Color: pos5Color,
      pos6Color: pos6Color,
      pos7Color: pos7Color,
      pos8Color: pos8Color,
      pos9Color: pos9Color,
      pos10Color: pos10Color,
      pos11Color: pos11Color,
      pos12Color: pos12Color,
    });
    setPositionLabels({
      ...positionLabels,
      pos2: pos2Label,
      pos3: pos3Label,
      pos4: pos4Label,
      pos5: pos5Label,
      pos6: pos6Label,
      pos7: pos7Label,
      pos8: pos8Label,
      pos9: pos9Label,
      pos10: pos10Label,
      pos11: pos11Label,
      pos12: pos12Label,
    });
  };

  const handleLoadPrescrption = () => {
    let error = false;
    let foundPrescrip = null;
    let foundPos = null;

    data.data.trays_set.map((tray) => {
      if (tray.plates.length > 0) {
        tray.plates.map((plate) => {
          if (plate.plate_barcode == barcode && plate.plate_status == "In Consolidation") {
            foundPrescrip = tray.prescription.prescription;
            foundPos = tray.prescription.assaypositions_set;
          }
        });
      }
    });
    console.log(foundPrescrip);
    console.log(foundPos);
    // setLoadedPrescription(foundPrescrip);
    // PrepPos.map((pos) => {
    //   if (pos.prescription === foundPrescrip) {
    //     foundPos = pos.positions;
    //   }
    // });

    if (foundPos === null || foundPrescrip === null) {
      error = true;
      console.log("did not find");
    } else {
      handleLoadPrescriptionClose(foundPrescrip, foundPos);
    }
  };

  const handleConirmAssayPlacement = () => {
    setConfirmAssayOpen(false);
    handleLoadPrescriptionOpen();
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Preparation - Order# {clientInfo.client_order}
            </Typography>
            <Typography style={{ color: "#feffff", marginBottom: "20px" }}>
              Plates Expected: {expectedPlates}
            </Typography>
            <Typography style={{ color: "#feffff", marginBottom: "20px" }}>
              Plates Scanned: {scannedPlateCount}
            </Typography>
            <Typography style={{ color: "red", marginBottom: "20px" }}>
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            {prep_blocks.map((block) => (
              <Grid key={`Block-${block.id}`} item xs={3}>
                <Paper
                  className={classes.robotTray}
                  style={{ backgroundColor: block.bgColor }}
                >
                  <Typography style={{ color: "white" }}>
                    {block.label}
                  </Typography>
                  {block.id === 1 && (
                    <Typography>Prescription: {loadedPrescription}</Typography>
                  )}
                  {block.id !== 1 && (
                    <React.Fragment>
                      <TextField
                        required
                        id={block.id}
                        name={block.id}
                        label={block.id === 1 ? "Assay Tray" : "Plate Barcode"}
                        className={classes.textField}
                        value={block.value}
                        disabled={block.disabled}
                        autoComplete=""
                        onChange={handleChange}
                      />
                      <Button
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        disabled={block.disabled}
                        onClick={() => verifyPlateData(block.id, block.label)}
                      >
                        verify
                      </Button>
                    </React.Fragment>
                  )}
                </Paper>
              </Grid>
            ))}
            {enableSubmit && (
              <Grid>
                <Button variant="contained" onClick={handleSubmit}>
                  {loading && (
                    <span>
                      Submitting..
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </span>
                  )}
                  {submitted && !loading && "Submitted"}
                  {!loading && !submitted && "Submit"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* {enabledOptions.submitOption && (
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", paddingTop: "50px" }}
            >
              <Button size="large" variant="contained" onClick={handleSubmit}>
                submit dna extraction
              </Button>
            </Grid>
          )} */}
      </Paper>
      {/* OPENS ON LOAD CONFIRM ASSAY TRAY */}
      <Dialog
        open={confirmAssayOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Assay Tray</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            place assay tray in position 1
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseFinishTrays} color="primary">
            No
          </Button> */}
          <Button
            onClick={handleConirmAssayPlacement}
            color="primary"
            autoFocus
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* OPENS SECOND ON LOAD SCAN IN ONE OF THE PLATES */}
      <Dialog
        open={loadPrescriptionOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Scan a Plate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              required
              id="plate1"
              name="plate1"
              label="Plate Barcode"
              className={classes.textField}
              value={formData.plate1}
              disabled={false}
              autoComplete=""
              onChange={handleChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoadPrescrption} color="primary" autoFocus>
            load
          </Button>
        </DialogActions>
      </Dialog>
      {/* PLATE CONFIRM */}
      <Dialog
        open={verifyPlateConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Plate is Valid</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            confirm plate is in correct position
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseFinishTrays} color="primary">
            No
          </Button> */}
          <Button onClick={handleCloseConfirmPlate} color="primary" autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={areYouSure}
        message="It seems you have unsaved changes, are you sure you want to leave this page?"
      />
    </React.Fragment>
  );
}
