import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axiosInstance from "../../../../axios";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:before": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:after": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "& .MuiSelect-select": {
      fontSize: "1.7rem",
      color: "#FEFFFF",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  icon: {
    fill: "#FEFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  robotTray: {
    // height: "125px",
    padding: "10%",
    // width: "190px",
    marginBottom: "10px",
  },
  blueRobotTray: {
    backgroundColor: "blue",
  },
  plate: {
    padding: "5%",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Dilution(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  const [traysToSubmit, setTraysToSubmit] = useState([]);

  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };

  const trayInfo = {
    // tray_count: data.data.trays_set.filter((tray) => tray.tray_status === "Order Received")
    tray_count: data.data.trays_set.filter(
      (tray) => tray.tray_status === "Samples Received"
    ).length,
    trays_to_process: data.data.trays_set.filter(
      (tray) => tray.tray_status === "Samples Received"
    ),
  };

  const initialData = {
    max: 4,
    process_amount: data.data.length,
    trays_scanned: 0,
    group: "",
    blueTrayBarcode: "",
    redTrayBarcode: "",
    greenTrayBarcode: "",
    yellowTrayBarcode: "",
    data_to_update: [],
  };
  const [formData, setFormData] = useState(initialData);

  const initialTextFieldState = {
    blue: false,
    red: true,
    green: true,
    yellow: true,
    finishTrays: true,
    submitOption: true,
  };
  const [enabledOptions, setEnabledOptions] = useState(initialTextFieldState);

  const color_placements = [
    {
      id: "blue",
      bg_color: "#007ac1",
      value: formData.blueTrayBarcode,
      disabled: enabledOptions.blue,
    },
    {
      id: "red",
      bg_color: "#b0003a",
      value: formData.redTrayBarcode,
      disabled: enabledOptions.red,
    },
    {
      id: "green",
      bg_color: "#087f23",
      value: formData.greenTrayBarcode,
      disabled: enabledOptions.green,
    },
    {
      id: "yellow",
      bg_color: "#c8b900",
      value: formData.yellowTrayBarcode,
      disabled: enabledOptions.yellow,
    },
  ];

  const initialTrayValues = {
    tray0: "",
    tray1: "",
    tray2: "",
    tray3: "",
  };

  const [trayValues, setTrayValues] = useState(initialTrayValues);
  const [openSubmitAlert, setOpenSubmitAlert] = useState(false);
  const [openPageSubmitAlert, setOpenPageSubmitAlert] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [groupMatch, setGroupMatch] = useState("");
  const [trayConfirmOpen, setTrayConfirmOpen] = useState(false);
  const [error, setError] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [group, setGroup] = useState("")
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openTrayGroupInfo, setOpenTrayGroupInfo] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const timer = React.useRef();
  
  let token = localStorage.getItem("access_token"); // get the access token from login
  let user = jwt_decode(token); // decode token to get info
  
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {}, [formData]);

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleSubmit = () => {
    // creat a group object and associate this plate to group object
    handleCloseSubmitAlert();

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .post("groups/", {
          order: clientInfo.client_order,
          tray_1:
            formData.blueTrayBarcode === "" ? null : formData.blueTrayBarcode,
          tray_2:
            formData.redTrayBarcode === "" ? null : formData.redTrayBarcode,
          tray_3:
            formData.greenTrayBarcode === "" ? null : formData.greenTrayBarcode,
          tray_4:
            formData.yellowTrayBarcode === ""
              ? null
              : formData.yellowTrayBarcode,
          user:user.user_id
        })
        .then((res) => {
          setAreYouSure(false)
          
          setGroup(res.data.id)
          handleOpenTrayGroupInfo();
          setLoading(false);
        });
    }
  };

  const verifyTrayData = (color) => {
    let error = false;
    let group_verified = false;
    let group = groupMatch;
    let tray_to_update = null;
    let found = false;
    let next = null;

    // check if barcode exist in data to update already
    formData.data_to_update.map((existingTray) => {
      if (existingTray == barcode) {
        setError(["tray was already scanned in"]);
        error = true;
      }
    });

    // if no error, find the tray associated with barcode
    if (!error) {
      trayInfo.trays_to_process.map((tray) => {
        if (tray.barcode == barcode) {
          found = true;
          tray_to_update = tray;
        }
      });

      // if we didn't find the tray in data set show error
      if (!found) {
        setError(["tray scanned was not found"]);
        error = true;
      } else {
        // we found the tray
        if (group === "") {
          // check if group exist
          setGroupMatch(tray_to_update.prescription.prescription);
          group_verified = true;
        } else if (tray_to_update.prescription.prescription != group) {
          // the groups DO NOT match so we throw error
          setError(["tray scanned does not match the tray prescription"]);
          error = true;
        } else {
          group_verified = true;
        }
      }
    }

    if (!error && found && group_verified && tray_to_update !== null) {
      setAreYouSure(true)
      setFormData({
        ...formData,
        trays_scanned: formData.trays_scanned + 1,
        data_to_update: [...formData.data_to_update, tray_to_update.barcode],
      });
      if (color === "blue") {
        next = "red";
      } else if (color === "red") {
        next = "green";
      } else if (color === "green") {
        next = "yellow";
      } else if (color === "yellow") {
        next = null;
      }
      setEnabledOptions({
        ...enabledOptions,
        [color]: true,
        [next]: false,
      });
      setError([""]);
      handleOpenTrayConfirm();
    } else {
      console.log(
        "something did not work: " +
          error +
          found +
          group_verified +
          tray_to_update
      );
    }
  };

  const handleChange = (e) => {
    setBarcode(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleOpenTrayConfirm = () => {
    setTrayConfirmOpen(true);
  };

  const handleCloseTrayConfirm = () => {
    setTrayConfirmOpen(false);
    setEnabledOptions({
      ...enabledOptions,
      submitOption: false,
    });
    if (formData.trays_scanned == formData.max) {
      handleOpenSubmitAlert();
    } else if (formData.trays_scanned == trayInfo.tray_count) {
      handleOpenSubmitAlert();
    }
  };

  // const trayAlertClose = () => {
  //   setOpenTrayAlert(false);
  // };

  const handleOpenTrayGroupInfo = () => {
    setOpenTrayGroupInfo(true);
  };

  const handleCloseTrayGroupInfo = () => {
    setOpenTrayGroupInfo(false);
    setSubmitted(true);
  };

  const handleOpenSubmitAlert = () => {
    setOpenSubmitAlert(true);
  };

  const handleCloseSubmitAlert = () => {
    setOpenSubmitAlert(false);
  };

  const handleOpenPageSubmitAlert = () => {
    setOpenPageSubmitAlert(true);
  };

  const handleClosePageSubmitAlert = () => {
    setOpenPageSubmitAlert(false);
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Dilution Process for Order: {clientInfo.client_order}
            </Typography>
            <Typography style={{ color: "#feffff", marginBottom: "20px" }}>
              Amount of Trays to Process: {trayInfo.tray_count}
            </Typography>
            <Typography style={{ color: "#feffff", marginBottom: "20px" }}>
              Trays ready to submit: {formData.trays_scanned}
            </Typography>
            <Typography style={{ color: "#feffff", marginBottom: "20px" }}>
              Tray prescription: {groupMatch}
            </Typography>
            <Typography style={{ color: "red", marginBottom: "20px" }}>
              {error}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                  >
                    {color_placements.map((color) => (
                      <Grid key={`Color-${color.id}`} item xs={12}>
                        <Paper
                          className={classes.robotTray}
                          style={{ backgroundColor: `${color.bg_color}` }}
                        >
                          <TextField
                            required
                            id={`${color.id}TrayBarcode`}
                            name={`${color.id}TrayBarcode`}
                            label="Tray Barcode"
                            className={classes.textField}
                            value={color.value}
                            // value={formData.blueTrayBarcode}
                            disabled={color.disabled}
                            autoComplete=""
                            onChange={handleChange}
                          />
                          <Button
                            variant="contained"
                            style={{ marginTop: "10px" }}
                            disabled={color.disabled}
                            onClick={() => verifyTrayData(color.id)}
                          >
                            confirm
                          </Button>
                        </Paper>
                      </Grid>
                    ))}
                    {formData.sub}
                    <Grid>
                      <Button
                        variant="contained"
                        className={buttonClassname}
                        disabled={enabledOptions.submitOption}
                        onClick={handleOpenSubmitAlert}
                      >
                        {loading && (
                          <span>
                            Submitting..
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          </span>
                        )}
                        {submitted && !loading && "Submitted"}
                        {!loading && !submitted && "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div>
        {/* SUBMIT CONFIRM FROM AMOUNT MATCH */}
        <Dialog
          open={openSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Trays Confirmed: {formData.data_to_update.length}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Submit Data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubmitAlert} color="primary">
              No
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* TRAY CONFIRM */}
        <Dialog
          open={trayConfirmOpen}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Tray Placement
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              has the tray been placed in appropriate color?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTrayConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* SUBMIT ALERT */}
        <Dialog
          open={openPageSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Done Adding Trays</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Submit data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubmitAlert} color="primary">
              No
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* SUBMIT FROM PAGE CLICK */}
        <Dialog
          open={openTrayGroupInfo}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Group {group} Created
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {group} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseSubmitAlert} color="primary">
              No
            </Button> */}
            <Button onClick={handleCloseTrayGroupInfo} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Prompt
        when={areYouSure}
        message="It seems you have unsaved changes, are you sure you want to leave this page?"
      />
    </React.Fragment>
  );
}
