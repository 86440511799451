const columns = [
  { id: "program", label: "Program", minWidth: 170 },
  { id: "sub_program", label: "Sub Program", minWidth: 170 },
  { id: "barcode", label: "Barcode", minWidth: 170 },
  { id: "cross_id", label: "Cross ID", minWidth: 170 },
  { id: "tray_id", label: "Tray ID", minWidth: 170 },
  { id: "prescription", label: "Prescription", minWidth: 170 },
  { id: "number_seedlings", label: "Number Seedlings", minWidth: 170 },
  { id: "empty_wells", label: "Empty Wells", minWidth: 170 },
  { id: "sampling_date", label: "Sampling Date", minWidth: 170 },
  { id: "shipping_date", label: "Shipping Date", minWidth: 170 },
  // { id: "seedling_num", label: "Seedling Numbers", minWidth: 170 },
  // { id: "markers", label: "Markers", minWidth: 170 },
  // { id: "extra_marker", label: "Extra Markers", minWidth: 170 },
  // { id: "num_markers", label: "# of Markers", minWidth: 170 },
  // {
  //   id: "date_collected",
  //   label: "Date Collected",
  //   minWidth: 170,
  //   format: (value) => value.toLocalString("en-US"),
  // },
  // { id: "date_shipped", label: "Date Shipped", minWidth: 170 },
  // { id: "date_arrived", label: "Date Arrived", minWidth: 170 },
];

export default columns;
