import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory, Prompt } from "react-router-dom";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  colors,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PlateCheckIn from "./plateCheckIn";
import Plates from "../../../TestData/plates";
import axiosInstance from "../../../../axios";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  checkoutTextField: {
    "& label.Mui-focused": {
      color: "#17252A",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#17252A",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#17252A",
      color: "#17252A",
    },
    "& .MuiInputBase-input": {
      color: "#17252A",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  helperText: {
    color: colors.red[600],
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Reading(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state; // data passed from parent selection
  // console.log(data);
  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };
  console.log(data.data);
  const trayInfo = {
    // tray_count: data.data.trays_set.filter((tray) => tray.tray_status === "Order Received")
    tray_count: data.data.plates.filter(
      (plate) => plate.plate_status === "In PCR"
    ).length,
    trays_to_process: data.data.plates.filter(
      (plate) => plate.plate_status === "In PCR"
    ),
  };
  const [barcode, setBarcode] = useState("");
  const [disableVerify, setDisableVerify] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [errorMessage, setErrorMessage] = useState([]);
  const [platesToVerify, setPlatesToVerify] = useState([]);
  const initialData = {
    expected: 0,
    verfied: 0,
    group: null,
    max: 21,
    verify_disable: false,
    plateCheck: "",
    data_to_read: [],
  };
  const [formData, setFormData] = useState(initialData);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSubmitAlert, setOpenSubmitAlert] = useState(false);
  const [openPageSubmitAlert, setOpenPageSubmitAlert] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submitOption, setSubmitOption] = useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [openPendingCheckout, setOpenPendingCheckout] = useState(false);
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState([""]);
  const [areYouSure, setAreYouSure] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {
    let plates = [];
    console.log(trayInfo.trays_to_process);
    trayInfo.trays_to_process.map((plate) => {
      plates.push(plate);
    });
    setPlatesToVerify(plates);
    setFormData({
      ...formData,
      expected: plates.length,
    });
  }, []);

  useEffect(() => {}, [formData]);
  useEffect(() => {}, [openSubmitAlert]);

  const verifyPlateData = () => {
    let error = false;
    let foundPlate = false;
    let plate_to_verify = null;
    let token = localStorage.getItem("access_token"); // get the access token from login
    let user = jwt_decode(token); // decode token to get info
    // Plates.map((plate) => {
    //   if (plate.barcode === barcode) {
    //     foundPlate = true;
    //     plate_to_verify = plate;
    //   }
    // });
    console.log(platesToVerify[0]);
    platesToVerify.map((plate) => {
      if (plate.plate_barcode == barcode) {
        foundPlate = true;
        plate_to_verify = plate;
      }
    });

    if (!foundPlate) {
      error = true;
      setErrorMessage(["plate scanned was not found"]);
    } else {
      // we found a plate that matches barcode
      // now we need to verify that the prescription matches
      // if (plate_to_verify.prescription !== loadedPrescription) {
      //   error = true;
      //   setErrorMessage(["plate scanned does not match prescription"]);
      // } else {
      // make sure plate was not already scanned
      scannedBarcodes.map((existingBarcode) => {
        if (existingBarcode.plate_barcode == barcode) {
          error = true;
          setErrorMessage(["barcode was already scanned"]);
        }
      });
      if (!error) {
        setErrorMessage([""]);
        setScannedBarcodes([
          ...scannedBarcodes,
          { plate_barcode: Number(barcode), order: clientInfo.client_order, user:user.user_id },
        ]);
        // setEnabledOptions({
        //   ...enabledOptions,
        //   [id]: true,
        // });
        setFormData({
          ...formData,
          verfied: formData.verfied + 1,
          data_to_read: [...formData.data_to_read, plate_to_verify],
        });
        setAreYouSure(true);
        setSubmitOption(true);
        handleAlertOpen();
      }
    }
  };

  const handleCheckoutPlate = (e) => {
    let verifyScannedIn = false;
    let verifyStatus = false;
    let status = "file extract";
    console.log(formData.plateCheck);
    scannedBarcodes.map((barcode) => {
      if (barcode === formData.plateCheck) {
        verifyScannedIn = true;
      } else {
        setCheckoutErrorMessage(["plate not scanned into reading"]);
      }
    });

    if (status === "file extracted") {
      verifyStatus = true;
    } else {
      setCheckoutErrorMessage(["file not exported"]);
    }

    if (verifyScannedIn && verifyStatus) {
      setCheckoutErrorMessage([""]);
      handleSubmitData(true);
    }
  };

  const handleSubmitData = (verified) => {
    // data is submitted to DB here
    // need to bulk update all data which is just a status change
    // then navigate back to the dashboard
    // let checkoutVerified = verified;
    handlePageSubmitAlertClose();
    handleSubmitAlertClose();
    // handlePendingCheckoutOpen();
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .post("analyzed/", scannedBarcodes)
        .then((res) => {
          setAreYouSure(false);
          setSubmitted(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handlePendingCheckoutOpen = () => {
    setOpenPendingCheckout(true);
  };

  const handlePendingCheckoutClose = () => {
    setOpenPendingCheckout(false);
  };

  const handlePageSubmitAlert = () => {
    setOpenPageSubmitAlert(true);
  };

  const handlePageSubmitAlertClose = () => {
    setOpenPageSubmitAlert(false);
    setBarcode("");
  };

  const handleSubmitAlertOpen = () => {
    setOpenSubmitAlert(true);
  };

  const handleSubmitAlertClose = () => {
    setOpenSubmitAlert(false);
    setBarcode("");
  };

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
    setBarcode("");
    if (
      formData.verfied === formData.expected ||
      formData.verfied === formData.max
    ) {
      setDisableVerify(true);
      handleSubmitAlertOpen();
    }
  };

  const handleChange = (e) => {
    if (e.target.id === "plateCheck") {
      setFormData({
        ...formData,
        plateCheck: e.target.value,
      });
    } else {
      setBarcode(e.target.value.trim());
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Reading - Order# {clientInfo.client_order}
              <Typography>Expected: {formData.expected}</Typography>
              <Typography>Verified: {formData.verfied}</Typography>
              <Typography>
                Remaining: {formData.expected - formData.verfied}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Scan in plates
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              className={classes.textField}
              required
              id="plateBarcode"
              name="plateBarcode"
              label="Plate Barcode"
              value={barcode}
              fullWidth
              autoComplete=""
              onChange={handleChange}
              helperText={errorMessage}
              // onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center", paddingTop: "15px" }}
          >
            <Button
              variant="contained"
              onClick={verifyPlateData}
              disabled={disableVerify}
            >
              Verify
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: "35px" }}>
            <PlateCheckIn platedata={formData.data_to_read} />
            {/* <EnhancedTable/> */}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", paddingTop: "35px" }}
          >
            {submitOption && (
              <Button
                variant="contained"
                className={buttonClassname}
                onClick={handlePageSubmitAlert}
                disabled={false}
              >
                {loading && (
                  <span>
                    Submitting..
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </span>
                )}
                {submitted && !loading && "Submitted"}
                {!loading && !submitted && "Submit"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <div>
        {/* PENDING CHECKOUT */}
        <Dialog
          open={openPendingCheckout}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Checkout Pending.."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                required
                id="plateCheck"
                name="plateCheck"
                label="Plate Barcode"
                className={classes.checkoutTextField}
                value={formData.plateCheck}
                disabled={false}
                autoComplete=""
                onChange={handleChange}
                helperText={checkoutErrorMessage}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Disagree
            </Button> */}
            <Button onClick={handleCheckoutPlate} color="primary" autoFocus>
              Checkout
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Reading Confirm"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Place Plate in Reading
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Disagree
            </Button> */}
            <Button onClick={handleAlertClose} color="primary" autoFocus>
              Confirmed
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Submit Data"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Data is ready to submit <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                Submit?
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitAlertClose} color="primary">
              No
            </Button>
            <Button onClick={handleSubmitData} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openPageSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Submit Data"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePageSubmitAlertClose} color="primary">
              No
            </Button>
            <Button
              onClick={() => handleSubmitData(false)}
              color="primary"
              autoFocus
            >
              Yes, Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Prompt
        when={areYouSure}
        message="It seems you have unsaved changes, are you sure you want to leave this page?"
      />
    </React.Fragment>
  );
}
