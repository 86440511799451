import React, { useEffect } from "react";
import axiosInstance from "../../axios";
import { useHistory } from "react-router-dom";

export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    axiosInstance.post("user/logout/blacklist/", {
      refresh_token: localStorage.getItem("refresh_token"),
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("client_id");
    localStorage.removeItem("first_name");
    localStorage.removeItem("email");
    axiosInstance.defaults.headers["Authorization"] = null;
    history.push("/login");
  });
  return <div></div>;
}
