import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory, Prompt } from "react-router-dom";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  colors,
  responsiveFontSizes,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TrayCheckIn from "./trayCheckIn";
import EnhancedTable from "./testNewDT";
import axiosInstance from "../../../../axios";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  helperText: {
    color: colors.red[600],
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ReceivingForm(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state; // data passed from parent selection
  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };
  const trayInfo = {
    // tray_count: data.data.trays_set.filter((tray) => tray.tray_status === "Order Received")
    tray_count: data.data.trays_set.filter(
      (tray) => tray.tray_status === "Order Placed"
    ).length,
    trays_to_process: data.data.trays_set.filter(
      (tray) => tray.tray_status === "Order Placed"
    ),
  };

  console.log(trayInfo.trays_to_process);
  const [barcode, setBarcode] = useState("");
  const [disableVerify, setDisableVerify] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [errors, setErrors] = useState([]);
  const initialData = {
    expected: data.data.length,
    verfied: 0,
    group: null,
    verify_disable: false,
    data_to_update: [],
    tray_objects: [],
  };
  const [formData, setFormData] = useState(initialData);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSubmitAlert, setOpenSubmitAlert] = useState(false);
  const [openPageSubmitAlert, setOpenPageSubmitAlert] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [focus, setFocus] = useState(true);
  const inputRef = useRef();
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  console.log({ inputRef });

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 10);
  }, [barcode]);

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {}, [formData]);
  useEffect(() => {}, [openSubmitAlert]);

  const verifyTrayData = () => {
    let error = false;
    let found = false;
    console.log(barcode);

    formData.data_to_update.map((existingTray) => {
      if (existingTray == barcode) {
        setErrors(["tray is already verified"]);
        error = true;
        setBarcode("");
      }
    });
    if (!error) {
      trayInfo.trays_to_process.map((tray) => {
        let existing = tray.barcode;
        if (existing == barcode) {
          found = true;
          setErrors([]);
          setFormData({
            ...formData,
            verfied: formData.verfied + 1,
            group: tray.prescription.prescription,
            data_to_update: [...formData.data_to_update, tray.barcode],
            tray_objects: [
              ...formData.tray_objects,
              {
                barcode: tray.barcode,
                tray_id: tray.tray_id,
                prescription: tray.prescription.prescription,
              },
            ],
          });
          setDisableSubmit(false);
          handleAlertOpen();
          setAreYouSure(true);
        }
      });
      if (!found) {
        setErrors(["tray barcode not found"]);
        setBarcode("");
      }
    }
  };

  const handleSubmitData = () => {
    handlePageSubmitAlertClose();
    handleSubmitAlertClose();
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .put("received/", {
          order_id: clientInfo.client_order,
          status: "In Receiving",
          tray_barcodes: formData.data_to_update,
        })
        .then((res) => {
          console.log(res.data);
          setAreYouSure(false);
          setSubmitted(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handlePageSubmitAlert = () => {
    setOpenPageSubmitAlert(true);
  };

  const handlePageSubmitAlertClose = () => {
    setOpenPageSubmitAlert(false);
    setBarcode("");
  };

  const handleSubmitAlertOpen = () => {
    setOpenSubmitAlert(true);
  };

  const handleSubmitAlertClose = () => {
    setOpenSubmitAlert(false);
    setBarcode("");
  };

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
    setBarcode("");
    console.log(inputRef);
    inputRef.current.focus();
    console.log(formData.tray_objects);
    if (formData.verfied === formData.expected) {
      setDisableVerify(true);
      handleSubmitAlertOpen();
    }
  };

  const handleChange = (e) => {
    setBarcode(e.target.value.trim());
  };

  const handleFocus = () => {
    setFocus(!focus);
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Receiving - Order# {data.data.id}
              <Typography>Expected: {trayInfo.tray_count}</Typography>
              <Typography>Verified: {formData.verfied}</Typography>
              <Typography>
                Remaining: {trayInfo.tray_count - formData.verfied}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Scan in trays
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              className={classes.textField}
              required
              id="trayBarcode"
              name="trayBarcode"
              label="Tray Barcode"
              // ref={inputRef}
              inputRef={inputRef}
              value={barcode}
              fullWidth
              autoComplete=""
              onChange={handleChange}
              helperText={errors}
              // autoFocus={focus}
              // onFocus={handleFocus}
              // onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center", paddingTop: "15px" }}
          >
            <Button
              variant="contained"
              onClick={verifyTrayData}
              disabled={disableVerify}
            >
              Verify
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: "35px" }}>
            <TrayCheckIn traydata={formData.tray_objects} />
            {/* <EnhancedTable/> */}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", paddingTop: "35px" }}
          >
            <Button
              variant="contained"
              className={buttonClassname}
              onClick={handlePageSubmitAlert}
              disabled={disableSubmit}
            >
              {loading && (
                <span>
                  Submitting..
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                </span>
              )}
              {submitted && !loading && "Submitted"}
              {!loading && !submitted && "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div>
        <Dialog
          open={openAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm group placement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Place this tray in group{" "}
              <Typography style={{ fontWeight: "bold", color: "black" }}>
                {formData.group}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Disagree
            </Button> */}
            <Button onClick={handleAlertClose} color="primary" autoFocus>
              Confirmed
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Submit Data"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Data is ready to submit <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                Submit?
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitAlertClose} color="primary">
              No
            </Button>
            <Button onClick={handleSubmitData} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openPageSubmitAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Submit Data"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePageSubmitAlertClose} color="primary">
              No
            </Button>
            <Button onClick={handleSubmitData} color="primary" autoFocus>
              Yes, Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Prompt
          when={areYouSure}
          message="It seems you have unsaved changes, are you sure you want to leave this page?"
        />
      </div>
    </React.Fragment>
  );
}
