import React, { useEffect, useState } from "react";
import axiosInstance from "./axios";
import jwt_decode from "jwt-decode";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Dashboard from "./components/Generic/Dashboard/dashboard";
import DashHeader from "./components/Generic/Header/dashHeader";

function App(props) {
  const history = useHistory();
  const test = props.userinfo;
  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   console.log(test)
  //   try {
  //     // success here means the user is authenticated
  //     console.log("anything")
  //     let token = localStorage.getItem("access_token");
  //     let user = jwt_decode(token);
  //     console.log(user)
  //     localStorage.setItem("email", user.email)

  //     axiosInstance
  //       .get(`user/` + user.user_id)
  //       .then((res) => {
  //         console.log(res)
  //         localStorage.setItem("client_id", res.data.user_client[0].client)
  //         if (user.is_superuser) {
  //           console.log('is super user')
  //           history.push({
  //             pathname: "/dashboard",
  //             state: { first_name: res.data.first_name },
  //           });
  //         } else if (user.is_end_user) {
  //           history.push({
  //             pathname: "/dashboard",
  //             state: { first_name: res.data.first_name },
  //           });
  //         } else if (user.is_staff) {
  //           history.push({
  //             pathname: "/cipher-lab",
  //             state: { first_name: res.data.first_name },
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         console.log("getting an error: " + e)
  //         history.push("/login");
  //       });
  //   } catch (e) {
  //     // this means that the user is not logged in
  //     console.log(JSON.stringify(e, null, 4));
  //     history.push("/login");
  //   }
  // }, []);

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    let user = jwt_decode(token);
    setUser(user);
    console.log("here");
  }, []);

  return (
    <div className="App">
      <h1>Hello</h1>
    </div>
  );
}

export default App;
