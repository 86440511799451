import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clients from "../TestData/orderData";
import client_order from "../TestData/client_to_order";
import axiosInstance from "../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function preventDefault(event) {
  event.preventDefault();
}

function Proceed(props) {
  // const onAlertChange = (alertStatus) => {

  // }
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        // onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm group placement"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Place this tray in group
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
              Disagree
            </Button> */}
          <Button
            onClick={props.onAlertChange(false)}
            color="primary"
            autoFocus
          >
            Confirmed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function Row(props) {
  const history = useHistory();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    client_name: {client_name: ""},
    client_order: "",
  });

  const classes = useRowStyles();

  const handleOrderClick = (id, name) => {
    // once user clicks an order then pop a confirm you want to work this order??
    // if they confirm pass the order id to the select station screen
    // if they deny close pop up
    setClientInfo({
      client_name: {client_name: name},
      client_order: id,
    });
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleAlertAgree = () => {
    history.push({ pathname: "/station-selection", state: clientInfo });
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{ backgroundColor: "lightGrey" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="h6">{row.client_name}</Typography>
        </TableCell>
        {/* <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Orders
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Select Order</TableCell>
                    <TableCell>Order Number</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    {/* <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.client_order.map((orderRow) => (
                    <TableRow key={orderRow.id}>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleOrderClick(orderRow.id, row.client_name)
                          }
                        >
                          Proceed w/ Order {orderRow.id}
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {orderRow.id}
                      </TableCell>
                      {/* <TableCell>{orderRow.is_active}</TableCell> */}
                      {/* <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openAlert}
        // onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Order Selection"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have choosen the following order{" "}
            <Typography variant="h6" style={{ fontWeight: "bolder" }}>
              order: {clientInfo.client_order}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "bolder" }}>
              client: {clientInfo.client_name.client_name}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAlertAgree} color="primary" autoFocus>
            Confirmed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default function OrderSelection(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [clients, setClients] = useState({
    clients_with_orders: null,
  });
  const [orderSelection, setOrderSelection] = useState({
    order: "",
    client: "",
    user: "",
    status: "",
    selected: false,
  });

  const handleOrderClick = (order_id) => {
    console.log(order_id);
  };

  const handleOrderChange = (order) => {
    setOrderSelection({
      order: order.id,
      client: order.client,
      user: order.user,
      status: order.status,
      selected: true,
    });
    // console.log(e);
  };

  const getClientOrders = (data) => {
    // simulate making a get request to the db
    // that returns all clients with active orders
    setClients({
      clients_with_orders: data,
    });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  useEffect(() => {
    axiosInstance.get('clients/').then((res)=> {
      console.log(res.data);
      getClientOrders(res.data);
    })
  }, []);
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography
          variant="h4"
          style={{
            color: "#feffff",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          Select an Order to Proceed
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {clients.clients_with_orders !== null && (
              <Paper>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#17252AE6",
                        }}
                      >
                        <TableCell />
                        <TableCell style={{ color: "#FEFFFF" }}>
                          <Typography variant="h5">Client Name</Typography>
                        </TableCell>
                        {/* <TableCell align="right">ADD MORE CELLS IF NECCESSARY</TableCell>*/}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clients.clients_with_orders
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 25, 50]}
                  component="div"
                  count={clients.clients_with_orders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
