import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, TextField } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:before": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:after": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "& .MuiSelect-select": {
      fontSize: "1.7rem",
      color: "#FEFFFF",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  icon: {
    fill: "#FEFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  robotTray: {
    // height: "125px",
    padding: "10%",
    // width: "190px",
    marginBottom: "10px",
  },
  blueRobotTray: {
    backgroundColor: "blue",
  },
  plate: {
    padding: "5%",
  },
}));

export default function DNAForm(props) {
  const dataPassedIn = {
    order: props.order
  }
  const dataToSubmit = {
    robot: "",
    plateBarcodes: [],
    trayBarcodes: [],
    colors: [],
  };
  const initialTextFieldState = {
    blueDisable: true,
    redDisable: true,
    greenDisable: true,
    yellowDisable: true,
    plate0: true,
    plate1: true,
    plate2: true,
    plate3: true,
    plate4: true,
    finishPlates: true,
    finishTrays: true,
    robotDisable: false,
    submitOption: false,
  };
  const initialPlateValues = {
    plate0: "",
    plate1: "",
    plate2: "",
    plate3: "",
    plate4: "",
  };
  const initialTrayValues = {
    tray0: "",
    tray1: "",
    tray2: "",
    tray3: "",
  };
  const classes = useStyles();
  const location = useLocation();
  const data = location.state;
  const [robot, setRobot] = useState("");
  // const [enableRobotSelect, setEnableRobotSelect] = useState(false);
  const [enabledOptions, setEnabledOptions] = useState(initialTextFieldState);
  const [plateValues, setPlateValues] = useState(initialPlateValues);
  const [trayValues, setTrayValues] = useState(initialTrayValues);
  // const [formData, updateFormData] = useState(initialFormData);
  const [plateBarcode, setPlateBarcode] = useState("");
  const [openPlateAlert, setOpenPlateAlert] = useState(false);
  const [openTrayAlert, setOpenTrayAlert] = useState(false);

  const handleSubmit = () => {
    
  }

  const handleChange = (e) => {
    if (e.target.name === "plateBarcode") {
      setPlateBarcode(e.target.value.trim());
    } else if (e.target.name === "robot") {
      setRobot(e.target.value);
      setEnabledOptions({
        ...enabledOptions,
        plate0: false,
      });
    }
  };

  const handlePlateChange = (e) => {
    if (e.target.id === "id-0") {
      setPlateValues({
        ...plateValues,
        plate0: e.target.value.trim(),
      });
    } else if (e.target.id === "id-1") {
      setPlateValues({
        ...plateValues,
        plate1: e.target.value.trim(),
      });
    } else if (e.target.id === "id-2") {
      setPlateValues({
        ...plateValues,
        plate2: e.target.value.trim(),
      });
    } else if (e.target.id === "id-3") {
      setPlateValues({
        ...plateValues,
        plate3: e.target.value.trim(),
      });
    } else if (e.target.id === "id-4") {
      setPlateValues({
        ...plateValues,
        plate4: e.target.value.trim(),
      });
    }
  };

  const handleTrayChange = (e) => {
    if (e.target.id === "blueTrayBarcode") {
      setTrayValues({
        ...trayValues,
        tray0: e.target.value.trim(),
      });
    } else if (e.target.id === "redTrayBarcode") {
      setTrayValues({
        ...trayValues,
        tray1: e.target.value.trim(),
      });
    } else if (e.target.id === "greenTrayBarcode") {
      setTrayValues({
        ...trayValues,
        tray2: e.target.value.trim(),
      });
    } else if (e.target.id === "yellowTrayBarcode") {
      setTrayValues({
        ...trayValues,
        tray3: e.target.value.trim(),
      });
    }
  };

  const handlePlateConfirm = (id, plateValue) => {
    if (id === 0 && plateValues.plate0 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        plate1: false,
        finishPlates: false,
      });
    } else if (id === 1 && plateValues.plate1 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        plate2: false,
      });
    } else if (id === 2 && plateValues.plate2 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        plate3: false,
      });
    } else if (id === 3 && plateValues.plate3 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        plate4: false,
      });
    } else if (id === 4 && plateValues.plate4 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        plate5: false,
      });
    }
  };

  const handleTrayConfirm = (id) => {
    if (id === "blueTrayBarcode" && trayValues.tray0 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        redDisable: false,
        finishTrays: false,
      });
    } else if (id === "redTrayBarcode" && trayValues.tray1 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        greenDisable: false,
      });
    } else if (id === "greenTrayBarcode" && trayValues.tray2 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        yellowDisable: false,
      });
    } else if (id === "yellowTrayBarcode" && trayValues.tray3 !== "") {
      setEnabledOptions({
        ...enabledOptions,
        greenDisable: false,
      });
    }
  };

  const handleAllPlateConfirm = () => {
    setEnabledOptions({
      ...enabledOptions,
      plate0: true,
      plate1: true,
      plate2: true,
      plate3: true,
      plate4: true,
      finishPlates: true,
      blueDisable: false,
      robotDisable: true,
    });
    plateAlertClose();
  };

  const handleAllTrayConfirm = () => {
    setEnabledOptions({
      ...enabledOptions,
      blueDisable: true,
      redDisable: true,
      greenDisable: true,
      yellowDisable: true,
      finishTrays: true,
      submitOption: true,
    });
    trayAlertClose();
  };

  const handleOpenPlateAlert = () => {
    setOpenPlateAlert(true);
  };

  const handleOpenTrayAlert = () => {
    setOpenTrayAlert(true);
  };

  const plateAlertClose = () => {
    setOpenPlateAlert(false);
  };

  const trayAlertClose = () => {
    setOpenTrayAlert(false);
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              DNA Extraction for order: {data.data[0].order_id}
            </Typography>
            <Typography
              variant="h6"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Select a Robot
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <Select
              className={classes.select}
              style={{ width: "25%" }}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="robot"
              value={robot}
              disabled={enabledOptions.robotDisable}
              onChange={handleChange}
            >
              <MenuItem value="Alpha">Alpha</MenuItem>
              <MenuItem value="Bravo">Bravo</MenuItem>
              <MenuItem value="Charlie">Charlie</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              //   justify="center"
              //   alignItems="stretch"
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  style={{
                    color: "#feffff",
                    marginTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  Robot Selected - {robot}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                  >
                    <Grid item xs={12}>
                      <Paper
                        className={classes.robotTray}
                        style={{ backgroundColor: "#007ac1" }}
                      >
                        <TextField
                          required
                          id="blueTrayBarcode"
                          name="blueTrayBarcode"
                          label="Tray Barcode"
                          className={classes.textField}
                          value={trayValues.tray0}
                          disabled={enabledOptions.blueDisable}
                          autoComplete=""
                          onChange={handleTrayChange}
                        />
                        <Button
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={enabledOptions.blueDisable}
                          onClick={() => handleTrayConfirm("blueTrayBarcode")}
                        >
                          confirm
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        className={classes.robotTray}
                        style={{ backgroundColor: "#b0003a" }}
                      >
                        <TextField
                          required
                          id="redTrayBarcode"
                          name="redTrayBarcode"
                          label="Tray Barcode"
                          className={classes.textField}
                          value={trayValues.tray1}
                          disabled={enabledOptions.redDisable}
                          autoComplete=""
                          onChange={handleTrayChange}
                        />
                        <Button
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={enabledOptions.redDisable}
                          onClick={() => handleTrayConfirm("redTrayBarcode")}
                        >
                          confirm
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        className={classes.robotTray}
                        style={{ backgroundColor: "#087f23" }}
                      >
                        <TextField
                          required
                          id="greenTrayBarcode"
                          name="greenTrayBarcode"
                          label="Tray Barcode"
                          className={classes.textField}
                          value={trayValues.tray2}
                          disabled={enabledOptions.greenDisable}
                          autoComplete=""
                          onChange={handleTrayChange}
                        />
                        <Button
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={enabledOptions.greenDisable}
                          onClick={() => handleTrayConfirm("greenTrayBarcode")}
                        >
                          confirm
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        className={classes.robotTray}
                        style={{ backgroundColor: "#c8b900" }}
                      >
                        <TextField
                          required
                          id="yellowTrayBarcode"
                          name="yellowTrayBarcode"
                          label="Tray Barcode"
                          className={classes.textField}
                          value={trayValues.tray3}
                          disabled={enabledOptions.yellowDisable}
                          autoComplete=""
                          onChange={handleTrayChange}
                        />
                        <Button
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={enabledOptions.yellowDisable}
                        >
                          confirm
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        disabled={enabledOptions.finishTrays}
                        onClick={handleOpenTrayAlert}
                      >
                        finish adding trays
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Grid container direction="column" alignItems="stretch">
                    <Grid item xs={12}>
                      <Paper
                        className={classes.plate}
                        style={{ backgroundColor: "#c66900" }}
                      >
                        {[0, 1, 2, 3, 4].map((plateBarcodeField) => (
                          <Grid key={plateBarcodeField} container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                id={"id-" + plateBarcodeField}
                                name={"plateBarcode-" + plateBarcodeField}
                                label={"Plate Barcode"}
                                className={classes.textField}
                                style={{ padding: "5%" }}
                                onChange={handlePlateChange}
                                value={
                                  plateBarcodeField === 0
                                    ? plateValues.plate0 || ""
                                    : plateBarcodeField === 1
                                    ? plateValues.plate1 || ""
                                    : plateBarcodeField === 2
                                    ? plateValues.plate2 || ""
                                    : plateBarcodeField === 3
                                    ? plateValues.plate3 || ""
                                    : plateBarcodeField === 4
                                    ? plateValues.plate4 || ""
                                    : ""
                                }
                                disabled={
                                  plateBarcodeField === 0
                                    ? enabledOptions.plate0
                                    : plateBarcodeField === 1
                                    ? enabledOptions.plate1
                                    : plateBarcodeField === 2
                                    ? enabledOptions.plate2
                                    : plateBarcodeField === 3
                                    ? enabledOptions.plate3
                                    : plateBarcodeField === 4
                                    ? enabledOptions.plate4
                                    : true
                                }
                                autoComplete=""
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                style={{ marginTop: "10px", padding: "5%" }}
                                disabled={
                                  plateBarcodeField === 0
                                    ? enabledOptions.plate0
                                    : plateBarcodeField === 1
                                    ? enabledOptions.plate1
                                    : plateBarcodeField === 2
                                    ? enabledOptions.plate2
                                    : plateBarcodeField === 3
                                    ? enabledOptions.plate3
                                    : plateBarcodeField === 4
                                    ? enabledOptions.plate4
                                    : true
                                }
                                onClick={() =>
                                  handlePlateConfirm(plateBarcodeField)
                                }
                              >
                                confirm
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          variant="contained"
                          disabled={enabledOptions.finishPlates}
                          style={{ marginTop: "15px" }}
                          onClick={handleOpenPlateAlert}
                        >
                          Finish adding plates
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {enabledOptions.submitOption && (
            <Grid item xs={12} style={{ textAlign: "center", paddingTop: '50px' }}>
              <Button size='large' variant="contained" onClick={handleSubmit}>submit dna extraction</Button>
            </Grid>
          )}
        </Grid>
      </Paper>
      <div>
        <Dialog
          open={openPlateAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Done Adding Plates</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to finish adding plates?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={plateAlertClose} color="primary">
              Disagree
            </Button>
            <Button onClick={handleAllPlateConfirm} color="primary" autoFocus>
              Confirmed
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openTrayAlert}
          // onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Done Adding Plates</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to finish adding trays?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={trayAlertClose} color="primary">
              Disagree
            </Button>
            <Button onClick={handleAllTrayConfirm} color="primary" autoFocus>
              Confirmed
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
