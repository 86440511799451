const PrepPos = [
  {
    id: 1,
    prescription: "prescription",
    positions: [
      {
        id: "FUS1",
      },
      {
        id: "FUS2",
      },
      {
        id: "FUS7",
      },
      {
        id: "MAC2",
      },
      {
        id: "MAC3",
      },
      {
        id: "ROM1",
      },
    ],
  },
  {
    id: 2,
    prescription: "prescription1",
    positions: [
      {
        id: "FUS3",
      },
      {
        id: "COL1",
      },
      {
        id: "MAC2",
      },
      {
        id: "MAC3",
      },
      {
        id: "PC1",
      },
      {
        id: "PC2",
      },
    ],
  },
];

export default PrepPos;
