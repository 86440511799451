import React, { useState } from "react";
// import { history } from 'react-router-dom';
import axiosInstance from "../../axios";
// material ui
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GenericHeader from "../Generic/Header/genericHeader";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helperText: {
    color: "red",
  },
}));

export default function Register() {
  // const history = useHistory();
  const initialFormData = Object.freeze({
    email: "",
    firstname: "",
    lastname: "",
    clientname: "",
    password: "",
    password2: "",
    error: false,
    errorMessage: {},
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    console.log(e.target.name + " " + e.target.value);
    const { name, value } = e.target;
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [name]: value.trim(),
    });
    // validateData();
  };

  const validateData = () => {
    let isError = false;
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(formData.email);
    if (!re.test(formData.email)) {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: { email: "not a valid email" },
      }));
    }
    if (formData.firstname === "") {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          firstname: "first name is required",
        },
      }));
    }
    if (formData.lastname === "") {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          lastname: "last name is required",
        },
      }));
    }
    if (formData.clientname === "") {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          clientname: "client name is required",
        },
      }));
    }
    if (formData.password === "" || formData.password2 === "") {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          password: "password is required",
        },
      }));
    }
    if (formData.password !== formData.password2) {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          password: "passwords must match",
          password2: "passwords must match",
        },
      }));
    }
    if (!isError) {
      isError = false;
      updateFormData((formData) => ({
        ...formData,
        error: false,
        errorMessage: {},
      }));
    }
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // validateData(formData);
    if (!validateData()) {
      console.log("here");
      axiosInstance
        .post(`user/register/`, {
          email: formData.email,
          first_name: formData.firstname,
          last_name: formData.lastname,
          client_name: formData.clientname,
          password: formData.password,
        })
        .then((res) => {
          console.log(res.data.detail);
          // history.push('/login');
          console.log(res);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    } else {
      console.log(validateData());
    }
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <GenericHeader />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}></Avatar>
        <Typography component="h1" variant="h5">
          Client Registration
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={formData.error ? formData.errorMessage.email : ""}
                // helperText={errors.errorMessage.email}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label="First Name"
                name="firstname"
                autoComplete="first name"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={
                  formData.error ? formData.errorMessage.firstname : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label="Last Name"
                name="lastname"
                autoComplete="last name"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={
                  formData.error ? formData.errorMessage.lastname : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                id="clientname"
                label="Client Name"
                name="clientname"
                autoComplete="client name"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={
                  formData.error ? formData.errorMessage.clientname : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={
                  formData.error ? formData.errorMessage.password : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant="outlined"
                required
                fullWidth
                name="password2"
                label="Password Confirmation"
                type="password"
                id="password2"
                autoComplete="current-password"
                onKeyUp={handleChange}
                onChange={handleChange}
                helperText={
                  formData.error ? formData.errorMessage.password2 : ""
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Register
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
