import React, { useState } from "react";
import axiosInstance from "../../axios";
import { useHistory } from "react-router-dom";
//MaterialUI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GenericHeader from "../Generic/Header/genericHeader";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helperText: {
    color: "red",
  },
}));

export default function SignIn(props) {
  const history = useHistory();

  const initialFormData = Object.freeze({
    email: "",
    password: "",
    error: false,
    errorMessage: {},
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const validateData = () => {
    let isError = false;
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(formData.email);
    if (!re.test(formData.email)) {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: { email: "not a valid email" },
      }));
    }
    if (formData.password === "") {
      isError = true;
      updateFormData((formData) => ({
        ...formData,
        error: true,
        errorMessage: {
          ...formData.errorMessage,
          password: "password is required",
        },
      }));
    }
    if (!isError) {
      isError = false;
      updateFormData((formData) => ({
        ...formData,
        error: false,
        errorMessage: {},
      }));
    }
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateData()) {
      axiosInstance
        .post(`token/`, {
          email: formData.email,
          password: formData.password,
        })
        .then((res) => {
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + localStorage.getItem("access_token");
          history.push("/dashboard");
        })
        .catch(function (e) {
          if (e.response) {
            if (e.response.status === 401) {
              updateFormData((formData) => ({
                ...formData,
                error: true,
                errorMessage: {
                  ...formData.errorMessage,
                  password:
                    "email or passsword is incorrect \n" +
                    "or account is not verifed",
                },
              }));
            }
          }
          // console.log(JSON.stringify(e, null, 4));
        });
    } else {
      console.log(validateData());
    }
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <GenericHeader/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}></Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
            helperText={formData.error ? formData.errorMessage.email : ""}
          />
          <TextField
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            helperText={formData.error ? formData.errorMessage.password : ""}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
