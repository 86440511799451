import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Chart from "../../ClientDashboard/Tracking/chart";
import Deposits from "../../ClientDashboard/Tracking/deposits";
import Orders from "../../ClientDashboard/OrderManagment/orders";
import DashHeader from "../Header/dashHeader";
import OrderSelection from "../../CipherLab/orderSelection";
import StationSelection from "../../CipherLab/stationSelection";
import ReceivingForm from "../../CipherLab/Stations/Recieving/receiving";
import DNAForm from "../../CipherLab/Stations/DNAExtraction/dnaExtractionForm";
import axiosInstance from "../../../axios";
import jwt_decode from "jwt-decode";
import Dilution from "../../CipherLab/Stations/Dilution/dilution";
import Consolidation from "../../CipherLab/Stations/Consolidation/consolidation";
import Preparation from "../../CipherLab/Stations/Preparation/preparation";
import Sequencing from "../../CipherLab/Stations/Sequencing/sequencing";
import Reading from "../../CipherLab/Stations/Reading/reading";
// import verify from "../../UserManagment/auth/userAuth";
// import {userAuth} from "../../UserManagment/auth/userAuth";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.25-2.com/">
        25:2 Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: "#def2f1",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    // backgroundColor: '#424242',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState({
    first_name: "",
    is_valid: false,
    is_staff: false,
    is_superuser: false,
    is_end_user: false,
    is_both: false,
  });

  useEffect(() => {
    userAuth();
  }, []);

  const userAuth = () => {
    let token = localStorage.getItem("access_token");
    let user = jwt_decode(token);
    console.log(localStorage.getItem("first_name"));

    setUserInfo({
      first_name: localStorage.getItem("first_name"),
      is_staff: user.is_staff,
      is_superuser: user.is_superuser,
      is_end_user: user.is_end_user
    })
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div>
      {/* {userInfo.is_valid == true && ( */}
        <div className={classes.root}>
          <CssBaseline />
          <DashHeader userinfo={userInfo} />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              {location.pathname == "/dashboard" &&
                (userInfo.is_superuser || userInfo.is_end_user) && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Orders />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              {location.pathname == "/cipher-lab" &&
                (userInfo.is_staff || userInfo.is_superuser) && (
                  <OrderSelection />
                )}
              {location.pathname == "/station-selection" &&
                (userInfo.is_staff || userInfo.is_superuser) && (
                  <StationSelection />
                )}
              {location.pathname == "/receiving" &&
                (userInfo.is_staff || userInfo.is_superuser) && (
                  <ReceivingForm />
                )}
              {location.pathname == "/dna-extraction" &&
                (userInfo.is_staff || userInfo.is_superuser) && <DNAForm />}
              {location.pathname == "/dilution" &&
                (userInfo.is_staff || userInfo.is_superuser) && <Dilution />}
              {location.pathname == "/consolidation" &&
                (userInfo.is_staff || userInfo.is_superuser) && (
                  <Consolidation />
                )}
              {location.pathname == "/preparation" &&
                (userInfo.is_staff || userInfo.is_superuser) && <Preparation />}
              {location.pathname == "/sequencing" &&
                (userInfo.is_staff || userInfo.is_superuser) && <Sequencing />}
              {location.pathname == "/reading" &&
                (userInfo.is_staff || userInfo.is_superuser) && <Reading />}
              {/* <Box pt={4}>
                <Copyright />
              </Box> */}
            </Container>
          </main>
        </div>
      {/* )} */}
    </div>
  );
}
