import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DvrIcon from "@material-ui/icons/Dvr";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export const superUserListItems = (
  <div style={{ color: "white" }}>
    <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/cipher-lab">
      <ListItemIcon>
        <DvrIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Lab Process" />
    </ListItem>
    {/* <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const clientUserListItems = (
  <div style={{ color: "white" }}>
    <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    {/* <ListItem button component={Link} to="/cipher-lab">
      <ListItemIcon>
        <DvrIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Lab Process" />
    </ListItem> */}
    {/* <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const labUserListItems = (
  <div style={{ color: "white" }}>
    {/* <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem> */}
    <ListItem button component={Link} to="/cipher-lab">
      <ListItemIcon>
        <DvrIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Lab Process" />
    </ListItem>
    {/* <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const bothLabAndClinetListItems = (
  <div style={{ color: "white" }}>
    <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/cipher-lab">
      <ListItemIcon>
        <DvrIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="Lab Process" />
    </ListItem>
    {/* <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div style={{ color: "white" }}>
    <ListSubheader inset style={{ color: "white" }}>
      Recent Order Files
    </ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="April 2021" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="April 2020" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon style={{ color: "white" }} />
      </ListItemIcon>
      <ListItemText primary="April 2019" />
    </ListItem>
  </div>
);
