import React, { useEffect, useState } from "react";
// import {Keyboard} from 'react-native';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PlateCountPerMarker from "../../../TestData/plate_per_marker";
import axiosInstance from "../../../../axios";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:before": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:after": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "& .MuiSelect-select": {
      fontSize: "1.7rem",
      color: "#FEFFFF",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  icon: {
    fill: "#FEFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  robotTray: {
    // height: "125px",
    padding: "10%",
    // width: "190px",
    marginBottom: "10px",
  },
  blueRobotTray: {
    backgroundColor: "blue",
  },
  plate: {
    padding: "5%",
  },
}));

export default function Consolidation() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  console.log(data);
  const trayMax = 4;
  const plateMax = 5;
  const [traysToUpdate, setTraysToUpdate] = useState([]);
  const [platesToCreate, setPlatesToCreate] = useState([]);
  const [barcodeGroup, setBarcodeGroup] = useState([]);
  const [prescription, setPrescription] = useState("");
  const [group, setGroup] = useState("");
  const [barcode, setBarcode] = useState("");
  const [finishTrayButt, setFinishTrayButt] = useState(true);
  const [finishPlateButt, setFinishPlateButt] = useState(true);
  const [submitButt, setSubmitButt] = useState(false);
  const [trayConfirmOpen, setTrayConfirmOpen] = useState(false);
  const [finishTrayConfirmOpen, setFinishTrayConfirmOpen] = useState(false);
  const [finishPlateConfirmOpen, setFinishPlateConfirmOpen] = useState(false);
  const [plateConfirmOpen, setPlateConfirmOpen] = useState(false);
  const [morePlatesConfirm, setMorePlatesConfirm] = useState(false);
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState([]);
  const [areYouSure, setAreYouSure] = useState(false);

  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };

  const tray_info = {
    tray_count: data.data.trays_set.filter(
      (tray) => tray.tray_status === "In Grouping"
    ).length,
    trays_to_process: data.data.trays_set.filter(
      (tray) => tray.tray_status === "In Grouping"
    ),
  };

  console.log(tray_info.trays_to_process);

  const initialLoad = {
    trayCount: traysToUpdate.length,
    plateCount: platesToCreate.length,
    platesNeeded: 0,
    trayMax: 4,
    groupID: "",
    blueTrayBarcode: "",
    redTrayBarcode: "",
    greenTrayBarcode: "",
    yellowTrayBarcode: "",
    plate1: "",
    plate2: "",
    plate3: "",
    plate4: "",
    plate5: "",
  };
  const [formData, setFormData] = useState(initialLoad);

  const initialTextFieldState = {
    blue: false,
    red: true,
    green: true,
    yellow: true,
    plate1: true,
    plate2: true,
    plate3: true,
    plate4: true,
    plate5: true,
    finishPlates: true,
    finishTrays: true,
    robotDisable: false,
    submitOption: false,
  };
  const [enabledOptions, setEnabledOptions] = useState(initialTextFieldState);

  const color_placements = [
    {
      id: "blue",
      bg_color: "#007ac1",
      value: formData.blueTrayBarcode,
      disabled: enabledOptions.blue,
    },
    {
      id: "red",
      bg_color: "#b0003a",
      value: formData.redTrayBarcode,
      disabled: enabledOptions.red,
    },
    {
      id: "green",
      bg_color: "#087f23",
      value: formData.greenTrayBarcode,
      disabled: enabledOptions.green,
    },
    {
      id: "yellow",
      bg_color: "#c8b900",
      value: formData.yellowTrayBarcode,
      disabled: enabledOptions.yellow,
    },
  ];

  const plate_fields = [
    {
      id: "plate1",
      value: formData.plate1,
      disabled: enabledOptions.plate1,
    },
    {
      id: "plate2",
      value: formData.plate2,
      disabled: enabledOptions.plate2,
    },
    {
      id: "plate3",
      value: formData.plate3,
      disabled: enabledOptions.plate3,
    },
    {
      id: "plate4",
      value: formData.plate4,
      disabled: enabledOptions.plate4,
    },
    {
      id: "plate5",
      value: formData.plate5,
      disabled: enabledOptions.plate5,
    },
  ];
  const initialPlateValues = {
    plate0: "",
    plate1: "",
    plate2: "",
    plate3: "",
    plate4: "",
  };
  const initialTrayValues = {
    tray0: "",
    tray1: "",
    tray2: "",
    tray3: "",
  };

  const [plateValues, setPlateValues] = useState(initialPlateValues);
  const [trayValues, setTrayValues] = useState(initialTrayValues);

  const [plateBarcode, setPlateBarcode] = useState("");
  const [openPlateAlert, setOpenPlateAlert] = useState(false);
  const [openTrayAlert, setOpenTrayAlert] = useState(false);

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {}, [formData]);
  useEffect(() => {
    // when prescription is set then we need run a query for amount of plates
    // needed. Should send a valid prescriptio and return a plate amount number.
    // Then we set plate amount on front end
    if (prescription !== "") {
      tray_info.trays_to_process.map((tray) => {
        if (tray.prescription.prescription == prescription) {
          setFormData({
            ...formData,
            platesNeeded: tray.prescription.num_positions,
          });
        }
      });
    }
  }, [prescription]);

  const handleSubmit = () => {
    handleCloseConfirmSubmit();
    setSubmitButt(true);
    setLoading(true);
    console.log(platesToCreate);
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .post("consolidated/", platesToCreate)
        .then((res) => {
          setAreYouSure(false);
          setSubmitted(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };

  const verifyTrayData = (color) => {
    // need to find the group of first scanned tray
    // use that group to determine the amount of plates
    // ** NOTE maybe plates can be assigned on load of data within useEffect???
    let error = false;
    let group_verified = false;
    let prescription_verified = false;
    let tray_to_update = null;
    let found = false;
    let next = null;

    // check if barcode exist in data to update already
    traysToUpdate.map((existingTray) => {
      if (existingTray == barcode) {
        setError(["tray was already scanned in"]);
        error = true;
      }
    });

    // if no error, find the tray associated with barcode
    if (!error) {
      tray_info.trays_to_process.map((tray) => {
        if (tray.barcode == barcode) {
          found = true;
          tray_to_update = tray;
        }
      });

      // if we didn't find the tray in data set show error
      if (!found) {
        setError(["tray scanned was not found"]);
        error = true;
      } else {
        // we found the tray
        if (prescription === "") {
          // check if group exist
          setPrescription(tray_to_update.prescription.prescription);
          setFinishTrayButt(false); // enable button
          prescription_verified = true;
        } else if (tray_to_update.prescription.prescription != prescription) {
          // the groups DO NOT match so we throw error
          setError(["prescription mismatch"]);
          error = true;
        } else {
          prescription_verified = true;
        }

        if (group === "") {
          setGroup(tray_to_update.tray_group.id);
          group_verified = true
        } else if (tray_to_update.tray_group.id != group) {
          setError(["group mismatch"]);
          error = true;
        } else {
          group_verified = true;
        }
      }
    }

    if (
      !error &&
      found &&
      prescription_verified &&
      group_verified &&
      tray_to_update !== null
    ) {
      setTraysToUpdate([...traysToUpdate, tray_to_update.barcode]);
      // setFormData({
      //   ...formData,
      //   trayCount: formData.trays_scanned + 1,
      //   data_to_update: [...formData.data_to_update, tray_to_update],
      // });
      console.log("verified");
      if (color === "blue") {
        next = "red";
      } else if (color === "red") {
        next = "green";
      } else if (color === "green") {
        next = "yellow";
      } else if (color === "yellow") {
        next = null;
      }
      setEnabledOptions({
        ...enabledOptions,
        [color]: true,
        [next]: false,
      });
      setAreYouSure(true);
      setError([""]);
      handleOpenTrayConfirm();
    } else {
      console.log("something did not work: ");
    }
  };

  const handleFinishAddingTrays = () => {
    // disable all tray fields and the button to finish trays
    // enable the plate barcodes
    setEnabledOptions({
      ...enabledOptions,
      blue: true,
      red: true,
      green: true,
      yellow: true,
      plate1: false,
    });
    setFinishTrayButt(true);
    handleCloseFinishTrays();
  };

  const handleFinishAddingPlates = () => {
    // check if we need to add more plates
    // if not then we can show submit button
    // if we need more plates to be added pop up dialog that we need to add more plates to continue
    if (formData.platesNeeded - platesToCreate.length > 0) {
      var additionalPlates = formData.platesNeeded - platesToCreate.length;
      handleCloseFinishPlates();
      handleAddMorePlates(additionalPlates);
    } else {
      handleCloseFinishPlates();
      handleOpenConfirmSubmit();
    }
  };

  const handleAddMorePlates = (num_plates) => {
    hanleOpenMorePlatesConfirm();
    setFormData({
      ...formData,
      plate1: "",
      plate2: "",
      plate3: "",
      plate4: "",
      plate5: "",
    });
    setEnabledOptions({
      ...enabledOptions,
      plate1: false,
      plate2: true,
      plate3: true,
      plate4: true,
      plate5: true,
    });
  };

  // const createPlate = (plate_barcode) => {
  //   return {plate_barcode};
  // };

  const verifyPlateData = (plateID) => {
    // make sure that the plate was placed in the machine
    // check to see if the plate max is hit
    // check to see if the plate amount is hit
    let error = false;
    let next = null;
    let plate_to_create = null;

    if (barcode != "") {
      platesToCreate.map((existingPlate) => {
        if (existingPlate.plate_barcode == barcode) {
          setError(["plate was already scanned in"]);
          error = true;
        }
      });

      console.log("TRAYS: " + traysToUpdate);

      traysToUpdate.map((tray) => {
        if (tray == barcode) {
          setError(["this barcode is assigned to a tray"]);
          error = true;
        }
      });

      console.log(formData.blueTrayBarcode);
      console.log(formData.redTrayBarcode);
      console.log(formData.greenTrayBarcode);
      console.log(formData.yellowTrayBarcode);

      if (!error) {
        let blue =
          formData.blueTrayBarcode === ""
            ? null
            : Number(formData.blueTrayBarcode);
        let red =
          formData.redTrayBarcode === ""
            ? null
            : Number(formData.redTrayBarcode);
        let green =
          formData.greenTrayBarcode === ""
            ? null
            : Number(formData.greenTrayBarcode);
        let yellow =
          formData.yellowTrayBarcode === ""
            ? null
            : Number(formData.yellowTrayBarcode);
        let token = localStorage.getItem("access_token"); // get the access token from login
        let user = jwt_decode(token); // decode token to get info

        setPlatesToCreate([
          ...platesToCreate,
          {
            plate_barcode: Number(barcode),
            blue_tray: blue,
            green_tray: green,
            red_tray: red,
            yellow_tray: yellow,
            order: data.data.id,
            user: user.user_id
          },
        ]);
        if (plateID === "plate1") {
          next = "plate2";
        } else if (plateID === "plate2") {
          next = "plate3";
        } else if (plateID === "plate3") {
          next = "plate4";
        } else if (plateID === "plate4") {
          next = "plate5";
        }

        setEnabledOptions({
          ...enabledOptions,
          [plateID]: true,
          [next]: false,
        });
        setError([""]);
        handleOpenPlateConfirm();
        setFinishPlateButt(false);
        setBarcode("");
      }
    } else {
      setError(["plate barcode cannot be empty"]);
    }
  };

  const handleChange = (e) => {
    setBarcode(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleOpenConfirmSubmit = () => {
    setSubmitConfirmOpen(true);
  };

  const handleCloseConfirmSubmit = () => {
    setSubmitConfirmOpen(false);
    setSubmitButt(true);
  };

  const hanleOpenMorePlatesConfirm = () => {
    setMorePlatesConfirm(true);
  };

  const handleCloseMorePlatesConfirm = () => {
    setMorePlatesConfirm(false);
  };

  const handleOpenFinishPlates = () => {
    setFinishPlateConfirmOpen(true);
  };

  const handleCloseFinishPlates = () => {
    setFinishPlateConfirmOpen(false);
  };

  const handleOpenFinishTrays = () => {
    setFinishTrayConfirmOpen(true);
  };

  const handleCloseFinishTrays = () => {
    setFinishTrayConfirmOpen(false);
  };
  const handleOpenTrayConfirm = () => {
    setTrayConfirmOpen(true);
  };

  const handleCloseTrayConfirm = () => {
    setTrayConfirmOpen(false);
    console.log(formData.trays_scanned);
    if (formData.trayCount == formData.trayMax) {
      setFinishTrayButt(true);
    }
  };

  const handleOpenPlateConfirm = () => {
    setPlateConfirmOpen(true);
  };

  const handleClosePlateConfirm = () => {
    setPlateConfirmOpen(false);
    if (platesToCreate.length === formData.platesNeeded) {
      setEnabledOptions({
        ...enabledOptions,
        plate1: true,
        plate2: true,
        plate3: true,
        plate4: true,
        plate5: true,
      });
      setFinishPlateButt(true);
      handleOpenConfirmSubmit();
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Consolidation - Order# {data.data.id}
              <Typography>Prescription: {prescription}</Typography>
              <Typography>Tray Group: {group}</Typography>
              <Typography>Plates Needed: {formData.platesNeeded}</Typography>
              <Typography>Plate Count: {platesToCreate.length}</Typography>
            </Typography>
            <Typography style={{ color: "red", marginBottom: "20px" }}>
              {error}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              //   justify="center"
              //   alignItems="stretch"
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                  >
                    {color_placements.map((color) => (
                      <Grid key={`Color-${color.id}`} item xs={12}>
                        <Paper
                          className={classes.robotTray}
                          style={{ backgroundColor: `${color.bg_color}` }}
                        >
                          <TextField
                            required
                            id={`${color.id}TrayBarcode`}
                            name={`${color.id}TrayBarcode`}
                            label="Tray Barcode"
                            className={classes.textField}
                            value={color.value}
                            // value={formData.blueTrayBarcode}
                            disabled={color.disabled}
                            autoComplete=""
                            onChange={handleChange}
                          />
                          <Button
                            variant="contained"
                            style={{ marginTop: "10px" }}
                            disabled={color.disabled}
                            onClick={() => verifyTrayData(color.id)}
                          >
                            confirm
                          </Button>
                        </Paper>
                      </Grid>
                    ))}
                    <Grid>
                      <Button
                        variant="contained"
                        disabled={finishTrayButt}
                        onClick={handleOpenFinishTrays}
                      >
                        finish adding trays
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Grid container direction="column" alignItems="stretch">
                    <Grid item xs={12}>
                      <Paper
                        className={classes.plate}
                        style={{ backgroundColor: "#c66900" }}
                      >
                        {plate_fields.map((field) => (
                          <Grid key={`Plate-${field.id}`} container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                id={field.id}
                                name={field.id}
                                label={"Plate Barcode"}
                                className={classes.textField}
                                style={{ padding: "5%" }}
                                onChange={handleChange}
                                value={field.value}
                                disabled={field.disabled}
                                autoComplete=""
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                style={{ marginTop: "10px", padding: "5%" }}
                                disabled={field.disabled}
                                onClick={() => verifyPlateData(field.id)}
                              >
                                confirm
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          variant="contained"
                          disabled={finishPlateButt}
                          style={{ marginTop: "15px" }}
                          onClick={handleOpenFinishPlates}
                        >
                          Finish adding plates
                        </Button>
                      </Paper>
                      {submitButt && (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid>
                            <Button variant="contained" onClick={handleSubmit}>
                              {loading && (
                                <span>
                                  Submitting..
                                  <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                </span>
                              )}
                              {submitted && !loading && "Submitted"}
                              {!loading && !submitted && "Submit"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {enabledOptions.submitOption && (
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", paddingTop: "50px" }}
            >
              <Button size="large" variant="contained" onClick={handleSubmit}>
                submit dna extraction
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
      {/* CONFIRM SUBMIT */}
      <Dialog
        open={submitConfirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ready To Submit?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            the data is ready to submit, continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmSubmit} color="primary">
            No
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* ADD MORE PLATES CONFIRM */}
      <Dialog
        open={morePlatesConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">More Plates Required</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            additional plates are required before submitting
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseFinishPlates} color="primary">
            No
          </Button> */}
          <Button
            onClick={handleCloseMorePlatesConfirm}
            color="primary"
            autoFocus
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* FINISH PLATE CONFIRM */}
      <Dialog
        open={finishPlateConfirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Finish adding {platesToCreate.length} plate(s)?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFinishPlates} color="primary">
            No
          </Button>
          <Button onClick={handleFinishAddingPlates} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* CONFIRM THAT PLATE IS IN THE MACHINE */}
      <Dialog
        open={plateConfirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Plate Placement
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            has the plate been placed in the robot?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePlateConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* TRAY CONFIRM FOR EACH TRAY PLACEMENT */}
      <Dialog
        open={trayConfirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Tray Placement
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            has the tray been placed in appropriate color?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTrayConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* FINISH ADDING TRAY CONFIRM */}
      <Dialog
        open={finishTrayConfirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Finish adding {traysToUpdate.length} tray(s)?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFinishTrays} color="primary">
            No
          </Button>
          <Button onClick={handleFinishAddingTrays} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={areYouSure}
        message="It seems you have unsaved changes, are you sure you want to leave this page?"
      />
    </React.Fragment>
  );
}
