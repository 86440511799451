import React, { useState } from "react";
import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import "./index.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import Register from "./components/UserManagment/register";
import Login from "./components/UserManagment/login";
import Logout from "./components/UserManagment/logout";
import Dashboard from "./components/Generic/Dashboard/dashboard";
import axiosInstance from "./axios";
import PrivateRoute from "./components/PrivateRoute/privateRoute";

// const [accessLevel, setAccessLevel] = useState({
//   is_superuser: false,
//   is_staff: false,
//   is_end_user: false,
// });

const getAuth = () => {
  try {
    let token = localStorage.getItem("access_token"); // get the access token from login
    let user = jwt_decode(token); // decode token to get info
    localStorage.setItem("email", user.email); // set the user email
    axiosInstance
      .get(`user/` + user.user_id)
      .then((res) => {
        // if (user.is_superuser) {
        //   setAccessLevel({
        //     is_superuser: true,
        //     is_staff: true,
        //     is_end_user: true,
        //   });
        // } else if (user.is_end_user) {
        //   localStorage.setItem("client_id", res.data.user_client[0].client);
        //   setAccessLevel({
        //     is_superuser: false,
        //     is_staff: false,
        //     is_end_user: true,
        //   });
        // } else if (user.is_staff) {
        //   setAccessLevel({
        //     is_superuser: false,
        //     is_staff: true,
        //     is_end_user: false,
        //   });
        // }
        console.log('true index')
        return true;
      })
      .catch((e) => {
        console.log("failed from index");
        return false;
      });
  } catch (error) {
    console.log("failed from index");
    return false;
  }
};

const routing = (
  <Router>
    {/* <React.StrictMode> */}
      <Switch>
        
        {/* PUBLIC ROUTES */}
        <Route exact path="/" component={Login}/>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/logout" component={Logout} />

        {/* PRIVATE ROUTES */}
        <PrivateRoute exact path='/dashboard' component={Dashboard}/>
        <PrivateRoute path="/cipher-lab" component={Dashboard} />
        <PrivateRoute path="/station-selection" component={Dashboard} />
        <PrivateRoute path="/receiving" component={Dashboard} />
        <PrivateRoute path="/dna-extraction" component={Dashboard} />
        <PrivateRoute path="/dilution" component={Dashboard} />
        <PrivateRoute path="/consolidation" component={Dashboard} />
        <PrivateRoute path="/preparation" component={Dashboard} />
        <PrivateRoute path="/sequencing" component={Dashboard} />
        <PrivateRoute path="/reading" component={Dashboard} />
      </Switch>
    {/* </React.StrictMode> */}
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
