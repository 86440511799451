import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import OrderSeedlings from "../TestData/orderSeedlingData";
import axiosInstance from "../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    height: 500,
    backgroundColor: "#17252A",
  },
  centerProgress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function StationSelection(props) {
  const classes = useStyles();
  const location = useLocation();
  const orderData = location.state;
  const availbleStation = "#00a152";
  const unavailableStation = "grey";
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  console.log(orderData);
  const [trayData, setTrayData] = useState(null);
  const [availbleStations, setAvailableStations] = useState({
    recieving: true,
    dilution: true,
    consolidation: true,
    preparation: true,
    sequencing: true,
    reading: true,
  });
  const stations = [
    {
      id: 1,
      name: "Receiving",
      pathname: "/receiving",
      status_req: "Order Placed",
      disabled: availbleStations.recieving,
    },
    {
      id: 2,
      name: "Dilution",
      pathname: "/dilution",
      status_req: "Samples Received",
      disabled: availbleStations.dilution,
    },
    {
      id: 3,
      name: "Consolidation",
      pathname: "/consolidation",
      status_req: "Dilution Complete",
      disabled: availbleStations.consolidation,
    },
    {
      id: 4,
      name: "Preparation",
      pathname: "/preparation",
      status_req: "Consolidation Complete",
      disabled: availbleStations.preparation,
    },
    {
      id: 5,
      name: "Sequencing",
      pathname: "/sequencing",
      status_req: "Preparation Complete",
      disabled: availbleStations.sequencing,
    },
    {
      id: 6,
      name: "Reading",
      pathname: "/reading",
      status_req: "Sequencing Complete",
      disabled: availbleStations.reading,
    },
  ];

  const getData = (data) => {
    // get request based on order id passed from selection
    // simulating getting an order with id = 1

    var recv = true;
    var dilu = true;
    var consol = true;
    var prep = true;
    var seq = true;
    var read = true;
    data.trays_set.map((tray) => {
      if (tray.tray_status === "Order Placed") {
        recv = false;
      }
      if (tray.tray_status === "Samples Received") {
        dilu = false;
      }
      if (tray.tray_status === "In Grouping") {
        consol = false;
      }
      if (tray.plates.length) {
        tray.plates.map((plate) => {
          if (plate.plate_status === "In Consolidation") {
            prep = false;
          }
          if (plate.plate_status === "In Preparation") {
            seq = false;
          }
          if (plate.plate_status === "In PCR") {
            read = false;
          }
        });
      }
    });
    setAvailableStations({
      recieving: recv,
      dilution: dilu,
      consolidation: consol,
      preparation: prep,
      sequencing: seq,
      reading: read,
    });
  };
  useEffect(() => {
    axiosInstance
      .get("order/" + orderData.client_order)
      .then((res) => {
        console.log(res.data);
        setTrayData(res.data);
        getData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <React.Fragment>
      {loading && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.centerProgress}
          style={{ textAlign: "center" }}
        >
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Paper elevation={3} className={classes.paper}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                style={{ color: "#feffff", marginBottom: "20px" }}
              >
                Select an Available Station
                <Typography>Order: {orderData.client_order}</Typography>
                <Typography>
                  Client: {orderData.client_name.client_name}
                </Typography>
              </Typography>
            </Grid>
            {stations.map((station) => (
              <Grid key={`Station-${station.id}`} item xs={12} md={4}>
                {trayData !== null && (
                  <Button
                    component={Link}
                    to={{
                      pathname: `${station.pathname}`,
                      state: {
                        // data: trayData.trays_set.filter(
                        //   (tray) => tray.tray_status === station.status_req
                        // ),
                        data: trayData,
                      },
                    }}
                    variant="contained"
                    style={
                      station.disabled
                        ? {
                            backgroundColor: unavailableStation,
                            width: "75%",
                            height: "100px",
                            margin: "10px",
                          }
                        : {
                            backgroundColor: availbleStation,
                            width: "75%",
                            height: "100px",
                            margin: "10px",
                          }
                    }
                    disabled={station.disabled}
                  >
                    {/* <Paper elevation={3}> */}
                    <Typography variant="h6">{station.name}</Typography>
                    {/* </Paper> */}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
}
