import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Plates from "../../../TestData/plates";
import axiosInstance from "../../../../axios";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:before": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "&:after": {
      color: "#FEFFFF",
      borderBottom: `2px solid #FEFFFF`,
    },
    "& .MuiSelect-select": {
      fontSize: "1.7rem",
      color: "#FEFFFF",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#FEFFFF",
    },
    "& label": {
      fontSize: "1.2rem",
      color: "#FEFFFF",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FEFFFF",
      color: "#FEFFFF",
    },
    "& .MuiInputBase-input": {
      color: "#FEFFFF",
    },
  },
  icon: {
    fill: "#FEFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#17252A",
  },
  robotTray: {
    // height: "125px",
    padding: "10%",
    // width: "190px",
    marginBottom: "10px",
  },
  blueRobotTray: {
    backgroundColor: "blue",
  },
  plate: {
    padding: "5%",
  },
}));

export default function Sequencing(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  const [barcode, setBarcode] = useState("");
  const [errorMessage, setErrorMessage] = useState([""]);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [submitOption, setSubmitOption] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const initialData = {
    block1: "",
    block2: "",
    block3: "",
    block4: "",
    pcrMachine: "",
    pcrMachineLabel: "",
  };
  const [formData, setFormData] = useState(initialData);

  const initialFieldState = {
    block1: true,
    block2: true,
    block3: true,
    block4: true,
    submitOption: true,
  };
  const [enabledOptions, setEnabledOptions] = useState(initialFieldState);

  const clientInfo = {
    client_name: data.data.client,
    client_order: data.data.id,
  };
  const plateInfo = {
    // tray_count: data.data.trays_set.filter((tray) => tray.tray_status === "Order Received")
    plate_count: data.data.plates.filter(
      (plate) => plate.plate_status === "In Preparation"
    ).length,
    plates_to_process: data.data.plates.filter(
      (plate) => plate.plate_status === "In Preparation"
    ),
  };

  const blocks = [
    {
      id: "block1",
      block_ID: 1,
      bg_color: "grey",
      value: formData.block1,
      disabled: enabledOptions.block1,
      label: "Block 1",
    },
    {
      id: "block2",
      block_ID: 2,
      bg_color: "grey",
      value: formData.block2,
      disabled: enabledOptions.block2,
      label: "Block 2",
    },
    {
      id: "block3",
      block_ID: 3,
      bg_color: "grey",
      value: formData.block3,
      disabled: enabledOptions.block3,
      label: "Block 3",
    },
    {
      id: "block4",
      block_ID: 4,
      bg_color: "grey",
      value: formData.block4,
      disabled: enabledOptions.block4,
      label: "Block 4",
    },
  ];

  useEffect(() => {
    if (submitted) {
      history.push({
        pathname: "/station-selection",
        state: clientInfo,
      });
    }
  }, [submitted]);

  useEffect(() => {}, formData);

  const handleSubmit = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axiosInstance
        .post("sequenced/", scannedBarcodes)
        .then((res) => {
          setAreYouSure(false);
          setSubmitted(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyBlockData = (id, block_id) => {
    let error = false;
    let foundPlate = false;
    let plate_to_verify = null;
    let token = localStorage.getItem("access_token"); // get the access token from login
    let user = jwt_decode(token); // decode token to get info

    plateInfo.plates_to_process.map((plate) => {
      if (plate.plate_barcode == barcode) {
        foundPlate = true;
        plate_to_verify = plate;
      }
    });

    if (!foundPlate) {
      error = true;
      setErrorMessage(["plate scanned was not found"]);
    } else {
      scannedBarcodes.map((existingBarcode) => {
        if (existingBarcode.plate_barcode == barcode) {
          error = true;
          setErrorMessage(["barcode was alreaady scanned"]);
        }
      });
      if (!error) {
        setAreYouSure(true);
        setErrorMessage([""]);
        setScannedBarcodes([
          ...scannedBarcodes,
          {
            plate_barcode: Number(barcode),
            machine_ID: Number(formData.pcrMachine),
            block_ID: Number(block_id),
            order: clientInfo.client_order,
            user:user.user_id
          },
        ]);
        setEnabledOptions({
          ...enabledOptions,
          [id]: true,
        });
        handleConfirmOpen();
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name !== "pcrMachine") {
      setBarcode(e.target.value.trim());
      console.log("here");
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
    } else {
      let pcrID = e.target.value.trim();
      let pcrMachineLabel =
        pcrID == 1
          ? "PCR1"
          : pcrID == 2
          ? "PCR2"
          : pcrID == 3
          ? "PCR3"
          : pcrID == 4
          ? "PCR4"
          : pcrID == 4
          ? "PCR5"
          : "PCR6";
      setEnabledOptions({
        ...enabledOptions,
        block1: false,
        block2: false,
        block3: false,
        block4: false,
      });
      setFormData({
        ...formData,
        block1: "",
        block2: "",
        block3: "",
        block4: "",
        [e.target.name]: e.target.value.trim(),
        pcrMachineLabel: pcrMachineLabel,
      });
      setScannedBarcodes([]);
    }
  };

  const handleSubmitOptionOpen = () => {
    setSubmitOption(true);
  };

  const handleSubmitOptionClose = () => {
    setSubmitOption(false);
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setEnabledOptions({
      ...enabledOptions,
      submitOption: false,
    });
    if (scannedBarcodes.length === 4) {
      handleSubmitOptionOpen();
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              Sequencing - Order# {clientInfo.client_order}
            </Typography>
            <Typography style={{ color: "red", marginBottom: "20px" }}>
              {errorMessage}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{ color: "#feffff", marginBottom: "20px" }}
            >
              select a machine
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <Select
              className={classes.select}
              style={{ width: "25%", marginBottom: "20px" }}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              labelId="pcrMachine"
              id="pcrMachine"
              name="pcrMachine"
              value={formData.pcrMachine}
              // disabled={enabledOptions.robotDisable}
              onChange={handleChange}
            >
              <MenuItem value="1" name="PCR1">
                PCR1
              </MenuItem>
              <MenuItem value="2">PCR2</MenuItem>
              <MenuItem value="3">PCR3</MenuItem>
              <MenuItem value="4">PCR4</MenuItem>
              <MenuItem value="5">PCR5</MenuItem>
              <MenuItem value="6">PCR6</MenuItem>
            </Select>
          </Grid>
          <Typography
            variant="subtitle2"
            style={{ color: "#feffff", marginBottom: "20px" }}
          >
            *Note if you change PCR machine the barcode(s) will be reset
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            {blocks.map((block) => (
              <Grid key={`Block-${block.id}`} item xs={6}>
                <Paper
                  className={classes.robotTray}
                  style={{ backgroundColor: `${block.bg_color}` }}
                >
                  <TextField
                    required
                    id={block.id}
                    name={block.id}
                    label={block.label}
                    className={classes.textField}
                    value={block.value}
                    disabled={block.disabled}
                    autoComplete=""
                    fullWidth
                    onChange={handleChange}
                  />
                  <Button
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    disabled={block.disabled}
                    onClick={() => verifyBlockData(block.id, block.block_ID)}
                  >
                    confirm
                  </Button>
                </Paper>
              </Grid>
            ))}
            <Grid>
              <Button
                variant="contained"
                style={{ textAlign: "center" }}
                disabled={enabledOptions.submitOption}
                onClick={handleSubmitOptionOpen}
              >
                {loading && (
                  <span>
                    Submitting..
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </span>
                )}
                {submitted && !loading && "Submitted"}
                {!loading && !submitted && "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* CONFIRM PLATE IS IN BLOCK */}
      <Dialog
        open={confirmOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Plate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            confirm that plate is in {formData.pcrMachineLabel}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseFinishTrays} color="primary">
            No
          </Button> */}
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* SUBMIT CONFIRM */}
      <Dialog
        open={submitOption}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Data Ready for Submission
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Looks like data is ready, submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitOptionClose} color="primary">
            No
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={areYouSure}
        message="It seems you have unsaved changes, are you sure you want to leave this page?"
      />
    </React.Fragment>
  );
}
