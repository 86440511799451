import React, { useEffect, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import Title from "../../title";
import columns from "./tableColumns";
import { Grid, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axiosInstance from "../../../axios";
import axios from "axios";

function preventDefault(event) {
  event.preventDefault();
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  container: {
    maxHeight: 440,
  },
}));

export default function Orders() {
  const classes = useStyles();
  const baseUrl = "https://www.cipherallele.com/api/"
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [excelFile, setExcelFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [appState, setAppState] = useState({
    isOpen: false,
    dataLoaded: false,
    isFormInvalid: false,
    uploadedFileName: "",
    submitOption: false,
    file: null,
    rows: null,
    cols: null,
  });

  useEffect(() => {}, [appState]);

  const renderFile = (fileObj) => {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setAppState({
          ...appState,
          dataLoaded: true,
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const fileHandler = (e) => {
    if (e.target.files.length) {
      let fileObj = e.target.files[0];
      let fileName = fileObj.name;

      setExcelFile(fileObj);

      // check for the correct filename extension
      if (fileName.slice(fileName.lastIndexOf(".") + 1) == "xlsx") {
        setAppState({
          ...appState,
          uploadedFileName: fileName,
          isFormInvalid: false,
          submitOption: true,
          file: fileObj,
        });
        renderFile(fileObj);
      } else {
        setAppState({
          isFormInvalid: true,
          uploadedFileName: "",
        });
      }
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const checkForDate = (date) => {
    try {
      let submittedDate = new Date((date - (25567 + 1)) * 86400 * 1000);
      let firstOfTheYear = new Date(new Date().getFullYear() - 1, 0, 1);
      if (submittedDate > firstOfTheYear) {
        return submittedDate.toDateString();
      } else {
        return date;
      }
    } catch (e) {
      return date;
    }
  };

  const handleError = () => {
    console.log("here");
    setFailed(true);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailed(false);
  };

  const handleSuccess = () => {
    setSuccess(true);
    setAppState({
      ...appState,
      uploadedFileName: "",
      dataLoaded: false,
    });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };

  const handleSubmit = () => {
    console.log(excelFile);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("client_id", localStorage.getItem("client_id"));
    formData.append("email", localStorage.getItem("email"));
    console.log("hurrr");
    console.log(baseUrl + "parse-seedling-file/")
    console.log("bla")
    axios({
      url: baseUrl + "parse-seedling-file/",
      method: "POST",
      // withCredentials: false,
      headers: {
        authorization: localStorage.getItem("access_token")
          ? "JWT " + localStorage.getItem("access_token")
          : null,
        "Content-Type": "multipart/form-data",
      },
      // data: formData,
      data: formData,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data["response"] === "success") {
          handleSuccess();
        } else {
          setErrorMessage(res.data["response"]);
          handleError();
        }
      })
      .catch((err) => {
        console.log(err.message);
        setErrorMessage(err.message);
        handleError();
      });
  };

  return (
    <React.Fragment>
      <Title>Order Upload</Title>
      <Button
        style={{
          maxWidth: "15%",
          maxHeight: "10%",
          minWidth: "10%",
          minHeight: "10%",
        }}
        variant="contained"
        component="label"
      >
        Upload File
        <input type="file" hidden onChange={fileHandler} />
      </Button>

      {appState.dataLoaded && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                      {/* {console.log(column)} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {appState.rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((row) => row[0] != "Program")
                  .map((row) => {
                    // console.log(row);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row[2]}
                      >
                        {appState.cols.map((column) => {
                          // need to check for empty or invalid data and put the error message above the table
                          // need to figure out the date formatting
                          const value = row[column.key];
                          let new_value = checkForDate(value);
                          return (
                            <TableCell key={column.key} align={column.align}>
                              {/* {column.format && typeof value === "number"
                                ? column.format(value)
                                : value} */}
                              {new_value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={appState.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      <div className={classes.seeMore}>
        <Button
          href={process.env.PUBLIC_URL + "/excelTemplate/order_template.xlsx"}
          variant="contained"
          download="order_template.xlsx"
        >
          Template Download
        </Button>
        <Typography> </Typography>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Data was submitted successully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={failed}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert onClose={handleCloseError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
