import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axiosInstance from "../../axios";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    try {
      let token = localStorage.getItem("access_token"); // get the access token from login
      let user = jwt_decode(token); // decode token to get info
      localStorage.setItem("email", user.email); // set the user email

      axiosInstance
        .get(`user/` + user.user_id)
        .then((res) => {
          console.log(res.data);
          if (res.data.user_client.length) {
            localStorage.setItem("client_id", res.data.user_client[0].client)
          }
          localStorage.setItem("first_name", res.data.first_name);
          setIsAuthenticated(true);
        })
        .catch((e) => {
          console.log("failed from index");
          setIsAuthenticated(false);
        });
    } catch (e) {
      console.log(e);
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
