const PlateCountPerMarker = [
  {
    id: 1,
    marker: "prescription",
    plate_amount: 6,
  },
  {
    id: 2,
    marker: "prescription1",
    plate_amount: 4,
  },
  {
    id: 3,
    marker: "prescription2",
    plate_amount: 5,
  },
];

export default PlateCountPerMarker;
